import { antdTheme, theme } from '@/core/theme'
import { Provider as AntdProvider } from '@ant-design/react-native'
import { useFonts } from 'expo-font'
import React from 'react'
import 'react-native-gesture-handler'
import { Provider as PaperProvider } from 'react-native-paper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import * as Sentry from 'sentry-expo'
import Routes from './src/routes'

Sentry.init({
  dsn:
    'https://a7b300d112744aa79c5bb9d00a0a7aa0@o742838.ingest.sentry.io/5787236',
  enableInExpoDevelopment: true,
  debug: true // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
})

const App: React.FC = () => {
  const [ready] = useFonts({
    antoutline: require('@ant-design/icons-react-native/fonts/antoutline.ttf'),
    antfill: require('@ant-design/icons-react-native/fonts/antfill.ttf')
    // 'material-community':
  })

  if (!ready) {
    return null
  }

  return (
    <PaperProvider theme={theme}>
      <AntdProvider theme={antdTheme}>
        <SafeAreaProvider>
          <Routes />
        </SafeAreaProvider>
      </AntdProvider>
    </PaperProvider>
  )
}

export default App
