import services from '@/services'
import { Icon, Toast } from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import { BarCodeScanner, PermissionStatus } from 'expo-barcode-scanner'
import * as ImagePicker from 'expo-image-picker'
import React from 'react'
import { Animated, Easing, StyleSheet, Text, View } from 'react-native'

export default function Scan() {
  const [hasPermission, setHasPermission] = React.useState<boolean | null>(null)
  const [scanned, setScanned] = React.useState(false)
  const [line_position, setlinePosition] = React.useState(new Animated.Value(0))

  const navigation = useNavigation()

  React.useEffect(() => {
    setTimeout(() => {
      lineAnimated()
    }, 1000)
    ;(async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync()
      setHasPermission(status === PermissionStatus.GRANTED)
    })()
  }, [])

  const handleBarCodeScanned = async ({ type, data }) => {
    setScanned(true)
    line_position.setValue(0)
    console.log('handleBarCodeScanned', data)
    // 拿这里的 id 去查
    const { data: item } = await services.userController.searchEasyUsingGET({
      param: data
    })
    if (item?.token) {
      navigation.navigate('Transfer', {
        ...item
      })
      setScanned(false)
    } else {
      Toast.fail(`无法识别该账户，请重新扫描`, 1, () => {
        setScanned(false)
      })
    }
  }

  const lineAnimated = () => {
    line_position.setValue(0)

    Animated.timing(line_position, {
      toValue: 300,
      duration: 3000,
      easing: Easing.linear,
      useNativeDriver: false
    }).start(() => {
      lineAnimated()
    })
  }

  const handleAlbumScan = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true
    })

    if (!result.cancelled) {
      Toast.loading('识别中', 1)
      const { base64 } = result

      console.log(base64)
      const [qrcode] = await BarCodeScanner.scanFromURLAsync(
        'data:image/png;base64,' + base64
      )
      console.log(qrcode.data)
      const { data } = await services.userController.searchEasyUsingGET({
        param: qrcode.data
      })
      console.log(data)
      if (data?.token) {
        navigation.navigate('Transfer', {
          ...data
        })
      } else {
        Toast.fail(`无法识别该账户: ${code}`)
      }
    }
  }
  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>
  }

  return (
    <View style={styles.container}>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={styles.preview}
      >
        <View style={styles.rectangleContainer}>
          <Animated.View
            style={[
              styles.line,
              { transform: [{ translateY: line_position }] }
            ]}
          />
          <View style={styles.rectangle}></View>
          <Text style={styles.rectangleText}>
            将二维码/条码放入框内，即可自动扫描
          </Text>
          <View>
            <Icon
              style={styles.albumbutton}
              color="#92BBD9"
              name="picture"
              size="md"
              onPress={handleAlbumScan}
            />
          </View>
        </View>
      </BarCodeScanner>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  preview: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  rectangleContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  rectangle: {
    height: 300,
    width: 300,
    borderWidth: 1,
    borderColor: '#92BBD9',
    backgroundColor: 'transparent'
  },
  rectangleText: {
    flex: 0,
    color: '#fff',
    marginTop: 10
  },
  border: {
    flex: 0,
    width: 200,
    height: 2,
    backgroundColor: '#92BBD9'
  },
  albumbutton: {
    flex: 0,
    marginTop: 50,
    backgroundColor: 'transparent'
  },
  line: {
    width: 280,
    height: 1,
    backgroundColor: 'rgba(30, 255, 145, 1)'
  }
})
