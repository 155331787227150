import Background from '@/components/Background'
import Button from '@/components/Button'
import SMSTextInput from '@/components/SMSTextInput'
import { AreaInput, PasswordInputText } from '@/components/TextInput'
import services from '@/services'
import { Toast } from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import React, { useRef, useState } from 'react'

const Register: React.FC = () => {
  const [value, setValue] = useState('')

  const [formattedValue, setFormattedValue] = useState('')

  const [area, setArea] = React.useState('+86')
  const [name, setName] = React.useState('')
  const [code, setCode] = React.useState('')
  const phoneInput = useRef<PhoneInput>(null)

  const [statePassword, setPassword] = React.useState<{
    password: string
    confirmPassword: string
  }>({
    password: '',
    confirmPassword: ''
  })

  const navigation = useNavigation()

  const handleRegisterPressed = async () => {
    if (statePassword.password !== statePassword.confirmPassword) {
      Toast.fail('两次密码不一致')
      return
    }
    if (!code) {
      Toast.fail('请输入手机验证码')
      return
    }
    const result = await services.userController.signupUsingPOST(
      {},
      {
        area,
        code,
        name,
        password: statePassword.confirmPassword
      }
    )
    if (result.code !== 200) {
      Toast.fail(result.msg)
      return
    }

    // 注册成功了，跳转到登录页
    Toast.success('注册成功')
    navigation.goBack()
  }
  const onSelect = (country: Country) => {
    console.log(country)
  }
  return (
    <Background
      containerStyle={{
        justifyContent: 'flex-start'
      }}
    >
      <AreaInput
        withShadow
        ref={phoneInput}
        defaultCode="CN"
        onChangeText={text => setName(text)}
        onChangeCountry={country => {
          setArea('+' + country.callingCode)
        }}
        placeholder="请输入手机号码"
      ></AreaInput>
      <SMSTextInput
        onChangeText={text => setCode(text.trim())}
        smsOpts={{
          to: name,
          area,
          type: 1
        }}
      />
      <PasswordInputText
        label="密码"
        onChangeText={text =>
          setPassword(prev => ({ ...prev, password: text }))
        }
        placeholder="请输入密码"
      />
      <PasswordInputText
        // dense
        label="确认密码"
        onChangeText={text =>
          setPassword(prev => ({ ...prev, confirmPassword: text }))
        }
        placeholder="请输入确认密码"
        errorText="密码长度8-32位，须包含数字、字母、符号至少2种以上元素"
      />

      <Button mode="contained" onPress={handleRegisterPressed}>
        注册
      </Button>
    </Background>
  )
}

export default Register
