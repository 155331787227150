import { IMGBB_KEY } from '@/constants'
import { theme } from '@/core/theme'
import { handleCopyData } from '@/core/utils'
import services from '@/services'
import {
  Button as AntdButton,
  Icon,
  InputItem,
  List,
  Picker,
  Radio,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { NavigationContainer, useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import axios from 'axios'
import * as ImagePicker from 'expo-image-picker'
import React from 'react'
import {
  Image,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import { Button, Caption, Title } from 'react-native-paper'
import QRCode from 'react-native-qrcode-svg'

const Tab = createMaterialTopTabNavigator()

const CashScreen = ({ navigation }) => {
  // 收款账号
  const [receipt, setReceipt] = React.useState<any>({
    bankId: '',
    bankName: '',
    userName: ''
  })
  const [bank, setBank] = React.useState(['农业银行'])
  const [currency, setCurrency] = React.useState(['CNY'])
  const [name, setName] = React.useState({ value: '', error: '' })
  const [amount, setAmount] = React.useState({ value: 0, error: '' })

  const [remark, setRemark] = React.useState({ value: '', error: '' })
  const [imgUrl, setImgUrl] = React.useState('')

  React.useEffect(() => {
    ;(async () => {
      if (Platform.OS !== 'web') {
        const {
          status
        } = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!')
        }
      }
    })()
  }, [])

  React.useEffect(() => {
    const setReceiptWithCurrency = async () => {
      if (currency?.[0]) {
        const {
          data: bankInfo
        } = await services.userController.banksysGetUsingGET({
          name: `${currency[0]}_deposit_bank`
        })
        setReceipt({
          bankId: bankInfo?.[0]?.card || '',
          bankName: bankInfo?.[0]?.bankName || '',
          userName: bankInfo?.[0]?.name || ''
        })
      }
    }
    setReceiptWithCurrency()
  }, [currency])

  const { data: rateMap } = useRequest(
    // 此处获取汇率，需要改动，变更为取https://min-api.cryptocompare.com/data/pricemulti?fsyms=CNY&tsyms=USDT,VND
    services.userController.rateGetUsingGET,
    {
      formatResult: res => res?.data
    }
  )

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true
    })

    console.log(result)

    if (!result.cancelled) {
      const { uri, base64 } = result
      const image = base64
        ? base64.replace(/^data:image\/[a-z]+;base64,/, '')
        : uri.replace(/^data:image\/[a-z]+;base64,/, '')

      console.log('uri', uri)
      console.log('base64', base64)

      // 上传到 CDN
      const formData = new FormData()
      formData.append('image', image)

      Toast.loading('上传中')

      const { status, data, error } = await axios
        .post(`https://api.imgbb.com/1/upload?key=${IMGBB_KEY}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => res?.data)

      if (status !== 200) {
        Toast.fail(error?.message)
        return
      }

      setImgUrl(data.url)
      Toast.removeAll()
    }
  }

  const handleReChange = async () => {
    if (Number.isNaN(Number(amount.value)) || Number(amount.value) <= 0) {
      // 异常值
      Toast.fail('请输入正确的金额')
      return false
    }
    if (name.value === '' || !name.value) {
      // 异常值
      Toast.fail('请输入转账人姓名')
      return false
    }

    const { data, msg, code } = await services.userController
      .applyDepositBankUsingPOST(
        {},
        {
          amount: amount.value,
          bank: receipt.bankId,
          currency: currency[0],
          remark: remark.value,
          name: name.value,
          imgUrl
        }
      )
      .catch(e => {
        Toast.fail(e.message)
      })
    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    Toast.success('已提交审核，请耐心等待', 1, () => {
      navigation.goBack()
    })
  }

  const money = Number.isNaN(
    Number(amount.value * rateMap?.['CNY'][currency?.[0]])
  )
    ? 0
    : amount.value * rateMap?.['CNY'][currency[0]]

  console.log(rateMap?.['CNY'])
  return (
    <ScrollView>
      <WhiteSpace size="lg" />
      <List>
        <Picker
          data={[
            { value: 'VND', label: '越南盾' },
            { value: 'CNY', label: '人民币' }
          ]}
          cols={1}
          value={currency}
          onChange={vals => {
            setCurrency(vals)
          }}
        >
          <List.Item arrow="horizontal">选择币种</List.Item>
        </Picker>
        <InputItem
          type="number"
          placeholder="请输入金额"
          // value={amount.value}
          onChangeText={text => {
            setAmount(prev => ({ ...prev, value: text }))
          }}
          extra={<Caption> GP</Caption>}
        >
          金额
        </InputItem>
      </List>

      <WingBlank>
        <View
          style={{
            backgroundColor: '#eee',
            height: 30,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
            marginBottom: 10
          }}
        >
          <Text style={{}}>
            当前汇率为1GP:{rateMap?.['CNY'][currency[0]]}
            {currency[0]}
          </Text>
        </View>
      </WingBlank>

      <List>
        <InputItem
          value={receipt.bankName}
          editable={false}
          extra={
            <Icon
              onPress={() => handleCopyData(receipt.bankName)}
              color={theme.colors.primary}
              name="copy"
            />
          }
        >
          收款银行
        </InputItem>
        <InputItem
          type="number"
          value={receipt.bankId}
          editable={false}
          extra={
            <Icon
              onPress={() => handleCopyData(receipt.bankId)}
              color={theme.colors.primary}
              name="copy"
            />
          }
        >
          收款卡号
        </InputItem>

        <InputItem
          value={receipt.userName}
          editable={false}
          extra={
            <Icon
              onPress={() => handleCopyData(receipt.userName)}
              color={theme.colors.primary}
              name="copy"
            />
          }
        >
          收款名
        </InputItem>

        <InputItem
          type="number"
          value={
            money.toFixed(3).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') +
            ' ' +
            currency[0]
          }
          editable={false}
          extra={
            <Icon
              onPress={() =>
                handleCopyData(
                  money
                    .toFixed(3)
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                )
              }
              color={theme.colors.primary}
              name="copy"
            />
          }
        >
          转账金额
        </InputItem>
      </List>

      <WhiteSpace size="lg" />

      <List>
        <InputItem
          type="text"
          value={name.value}
          placeholder="请输入转账人姓名"
          onChangeText={text => setName(prev => ({ ...prev, value: text }))}
        >
          转入姓名
        </InputItem>
        <InputItem
          type="text"
          value={remark.value}
          placeholder="请输入备注"
          onChangeText={text => setRemark(prev => ({ ...prev, value: text }))}
        >
          备注
        </InputItem>

        <List.Item
          arrow="horizontal"
          extra={'请上传转账截图'}
          onPress={pickImage}
        >
          转账凭证
        </List.Item>

        {imgUrl ? (
          <List.Item>
            <TouchableOpacity
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 100,
                height: 100,
                borderColor: '#ccc',
                borderStyle: 'dashed',
                borderWidth: 1
              }}
              onPress={pickImage}
            >
              <Image
                source={{ uri: imgUrl }}
                style={{ width: 100, height: 100 }}
              />
            </TouchableOpacity>
          </List.Item>
        ) : null}
        {/* <AntdImagePicker
  files={imgUrl}
  onChange={imgUrl => setImgUrl(imgUrl)}
/> */}

        {/* <Button onPress={pickImage}>Pick an image from camera roll</Button>
{imgUrl ? (
  <Image source={{ uri: imgUrl }} style={{ width: 200, height: 200 }} />
) : null} */}
      </List>

      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />

      <Text
        style={{
          color: 'gray',
          marginTop: 10,
          marginLeft: 10,
          marginBottom: 10
        }}
      >
        请注意，由于汇率的实时变化，我们将以您实际充值请求提交时的实时汇率为准自动转换为GP。
      </Text>
      <AntdButton type="primary" onPress={handleReChange}>
        提交充值
      </AntdButton>
    </ScrollView>
  )
}

const USDTScreen = ({ navigation }) => {
  const [chain, setChain] = React.useState([])
  const [address, setAddress] = React.useState({ value: '', error: '' })
  const [amount, setAmount] = React.useState({ value: 0, error: '' })

  const [showAddr, setShowAddr] = React.useState(false)

  const { data: rateMap } = useRequest(
    services.userController.rateGetUsingGET,
    {
      formatResult: res => res?.data
    }
  )

  const { data, loading } = useRequest(
    () => services.userController.getAddrUsingGET({ chain: chain?.[0] }),
    {
      ready: showAddr
    }
  )

  const handleNextStep = () => {
    setShowAddr(true)
  }

  const handleUSDTRecharge = async () => {
    if (Number.isNaN(Number(amount.value)) || Number(amount.value) <= 0) {
      // 异常值
      Toast.fail('请输入正确的金额')
      return false
    }
    if (address.value === '' || !address.value) {
      // 异常值
      Toast.fail('请输入转账地址')
      return false
    }
    const { data, msg, code } = await services.userController
      .applyDepositDigitalUsingPOST({
        amount: amount.value,
        address: address.value,
        chain: chain
      })
      .catch(e => {
        Toast.fail(e.message)
        return
      })

    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    Toast.success('提币申请已提交')
    navigation.goBack()
  }

  const money = Number.isNaN(Number(amount.value * rateMap?.['CNY']['USDT']))
    ? 0
    : amount.value * rateMap?.['CNY']['USDT']

  return (
    <ScrollView>
      {showAddr ? (
        <View>
          {loading ? (
            <View></View>
          ) : data?.code === 200 ? (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff'
              }}
            >
              {/*<View
                style={{
                  backgroundColor: theme.colors.primary,
                  paddingVertical: 20,
                  paddingHorizontal: 16,
                  width: '100%'
                }}
              >
                /*<Title style={{ color: '#fff' }}>全自动USDT充值</Title>
                <WhiteSpace />
                <Caption style={{ color: '#fff' }}>
                  温馨提示：本平台USDT钱包为全自动到账区块链钱包，转入USDT后，将自动以实时汇率换算为GP充值到您的账户中。
                </Caption>
              </View>*/}
              <View style={{ paddingHorizontal: 0, marginVertical: 16 }}>
                <WingBlank style={{ alignItems: 'center' }}>
                  <Text
                    style={{
                      fontSize: 25
                    }}
                  >
                    [{chain}]网络充值地址
                  </Text>
                  <WhiteSpace size="lg" />
                  <QRCode size={200} value={data.data} />

                  <Text onPress={() => handleCopyData(data?.data)}>
                    {data?.data}
                  </Text>

                  <List style={{ width: '100%' }}>
                    <InputItem
                      type="text"
                      value={data?.data}
                      editable={false}
                      extra={
                        <Icon
                          onPress={() => handleCopyData(data?.data)}
                          color={theme.colors.primary}
                          name="copy"
                        />
                      }
                    >
                      转账地址
                    </InputItem>

                    <InputItem
                      type="text"
                      value={money.toFixed(3) + 'USDT'}
                      editable={false}
                      extra={
                        <Icon
                          onPress={() => handleCopyData(money.toFixed(3))}
                          color={theme.colors.primary}
                          name="copy"
                        />
                      }
                    >
                      转账金额
                    </InputItem>
                    <InputItem
                      type="text"
                      value={address.value}
                      placeholder="请输入您的转账ID后8位"
                      onChangeText={text =>
                        setAddress(prev => ({ ...prev, value: text }))
                      }
                    >
                      转账ID(TxID)
                    </InputItem>

                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />

                    <AntdButton type="primary" onPress={handleUSDTRecharge}>
                      提交充值
                    </AntdButton>
                  </List>
                </WingBlank>
              </View>
            </View>
          ) : (
            Toast.fail('Error:' + data?.msg)
          )}
        </View>
      ) : (
        <View>
          <List>
            <WhiteSpace size="lg" />
            <Picker
              data={[
                { value: 'ETHEREUM', label: 'ERC20 - USDT' },
                { value: 'TRON', label: 'TRC20 - USDT' }
              ]}
              cols={1}
              value={chain}
              onChange={vals => {
                setChain(vals)
              }}
            >
              <List.Item arrow="horizontal">选择链类型</List.Item>
            </Picker>
            <WhiteSpace size="lg" />

            <InputItem
              type="number"
              placeholder="请输入金额"
              onChangeText={text => {
                setAmount(prev => ({ ...prev, value: text }))
              }}
              extra={<Caption> GP</Caption>}
            >
              金额
            </InputItem>
            <WhiteSpace size="lg" />
            <Button mode="contained" onPress={handleNextStep}>
              下一步
            </Button>
          </List>
        </View>
      )}
    </ScrollView>
  )
}

const StepItem = ({ title, name }) => {
  return (
    <View style={{ alignItems: 'center' }}>
      <Icon name={name} color="#fff" style={{ marginBottom: 8 }} />
      <Text style={{ color: '#fff' }}>{title}</Text>
    </View>
  )
}
const VIPScreen = () => {
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <View
        style={{
          backgroundColor: theme.colors.primary,
          paddingVertical: 20,
          paddingHorizontal: 16,
          width: '100%'
        }}
      >
        <Title style={{ color: '#fff' }}>VIP 充值流程</Title>
        <WhiteSpace />
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StepItem title="联系客服" name="message" />
          <Icon color="#fff" name="arrow-right" />
          <StepItem title="线下转账" name="credit-card" />
          <Icon color="#fff" name="arrow-right" />
          <StepItem title="确认充值" name="check-circle" />
        </View>
      </View>
      <View style={{ paddingHorizontal: 16, marginVertical: 16 }}>
        <Title>服务对象</Title>
        <Caption>
          针对大额充值的VIP客户，我们提供了一对一的在线充值渠道，如有需要或任何疑问，请联系客服咨询。
        </Caption>
      </View>
    </View>
  )
}

const OLScreen = () => {
  const [channel, setChannel] = React.useState(1)
  const [amount, setAmount] = React.useState({ value: 0, error: '' })
  const [remark, setRemark] = React.useState({ value: '', error: '' })
  const [step, setStep] = React.useState(1)

  const [receipt, setReceipt] = React.useState<any>({
    bankId: '',
    bankName: '',
    userName: ''
  })

  const { data: rateMap } = useRequest(
    services.userController.rateGetUsingGET,
    {
      formatResult: res => res?.data
    }
  )

  const goNext = async () => {
    if (Number.isNaN(Number(amount.value)) || Number(amount.value) <= 0) {
      // 异常值
      Toast.fail('请输入正确的金额')
      return false
    }
    const { data, code, msg } = await services.assetController.onlinePayPOST(
      {},
      {
        amount: amount.value,
        type: channel
      }
    )
    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    console.log(data)
    setReceipt({
      bankId: data.data.qrcodeKey || '',
      bankName: data.data.bankName || '',
      userName: data.data.realName || '',
      money: amount.value
    })

    setStep(2)
  }

  return (
    <View>
      <Title
        style={{ color: '#000', paddingVertical: 15, paddingHorizontal: 16 }}
      >
        请选择充值通道
      </Title>
      <WhiteSpace />
      <ScrollView>
        <List>
          <Radio.RadioItem
            checked={channel === 1}
            onChange={event => {
              if (event.target.checked) {
                setChannel(1)
              }
            }}
          >
            越南盾卡转卡支付
          </Radio.RadioItem>
          <Radio.RadioItem
            checked={channel === 2}
            onChange={event => {
              if (event.target.checked) {
                setChannel(2)
              }
            }}
            disabled
          >
            人民币卡转卡支付
          </Radio.RadioItem>
        </List>

        <WingBlank>
          <View
            style={{
              backgroundColor: '#eee',
              height: 30,
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
              marginBottom: 10
            }}
          >
            <Text style={{}}>当前汇率为1GP:{rateMap?.['CNY']['VND']}VND</Text>
          </View>
        </WingBlank>

        <List>
          <InputItem
            type="number"
            placeholder="请输入金额"
            onChangeText={text => {
              setAmount(prev => ({
                ...prev,
                value: text * rateMap?.['CNY']['VND']
              }))
            }}
            extra={<Caption> GP</Caption>}
          >
            金额
          </InputItem>
        </List>

        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
      </ScrollView>

      {step == 2 ? (
        <ScrollView>
          <Text
            style={{
              color: '#000',
              paddingVertical: 15,
              paddingHorizontal: 16
            }}
          >
            请复制以下转账信息进行银行卡转账！转账成功后15分钟内GP自动到账！
          </Text>
          <List>
            <InputItem
              value={receipt.bankName}
              editable={false}
              extra={
                <Icon
                  onPress={() => handleCopyData(receipt.bankName)}
                  color={theme.colors.primary}
                  name="copy"
                />
              }
            >
              收款银行
            </InputItem>
            <InputItem
              type="number"
              value={receipt.bankId}
              editable={false}
              extra={
                <Icon
                  onPress={() => handleCopyData(receipt.bankId)}
                  color={theme.colors.primary}
                  name="copy"
                />
              }
            >
              收款卡号
            </InputItem>

            <InputItem
              value={receipt.userName}
              editable={false}
              extra={
                <Icon
                  onPress={() => handleCopyData(receipt.userName)}
                  color={theme.colors.primary}
                  name="copy"
                />
              }
            >
              收款名
            </InputItem>

            <InputItem
              type="number"
              value={receipt.money
                .toFixed(3)
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')}
              editable={false}
              extra={
                <Icon
                  onPress={() =>
                    handleCopyData(
                      String(
                        receipt.money
                          .toFixed(3)
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                      )
                    )
                  }
                  color={theme.colors.primary}
                  name="copy"
                />
              }
            >
              转账金额
            </InputItem>
            <Caption
              style={{
                color: 'red',
                paddingVertical: 15,
                paddingHorizontal: 16
              }}
            >
              注意！请勿修改订单金额，修改金额导致的不到账平台概不负责！
            </Caption>
          </List>

          <WhiteSpace size="lg" />
        </ScrollView>
      ) : (
        <Button mode="contained" onPress={goNext}>
          提交充值
        </Button>
      )}
    </View>
  )
}

const ReChange: React.FC<any> = () => {
  const navigation = useNavigation()

  return (
    <NavigationContainer independent>
      <Tab.Navigator>
        <Tab.Screen name="现金充值">
          {props => <CashScreen {...props} navigation={navigation} />}
        </Tab.Screen>
        <Tab.Screen name="在线充值">
          {props => <OLScreen {...props} />}
        </Tab.Screen>
        <Tab.Screen name="USDT充值">
          {props => <USDTScreen {...props} navigation={navigation} />}
        </Tab.Screen>
        <Tab.Screen name="VIP充值">
          {props => <VIPScreen {...props} />}
        </Tab.Screen>
      </Tab.Navigator>
    </NavigationContainer>
  )
}

export default ReChange
