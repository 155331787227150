import { IMGBB_KEY } from '@/constants'
import services from '@/services'
import { List, Toast, WhiteSpace } from '@ant-design/react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import axios from 'axios'
import * as ImagePicker from 'expo-image-picker'
import React from 'react'
import { Platform, ScrollView, View } from 'react-native'
import { Avatar } from 'react-native-paper'
const Item = List.Item

const Profile = () => {
  const navigation = useNavigation()
  const [headUrl, setHeadUrl] = React.useState()
  const isFocused = useIsFocused()

  const { data: userData } = useRequest(services.userController.getUsingGET2, {
    formatResult: res => res?.data,
    ready: isFocused,
    refreshDeps: [isFocused]
  })

  console.log('userDatauserDatauserData', userData)

  React.useEffect(() => {
    ;(async () => {
      if (Platform.OS !== 'web') {
        const {
          status
        } = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!')
        }
      }
    })()
  }, [])

  React.useEffect(() => {
    if (userData?.headUrl) {
      setHeadUrl(userData.headUrl)
    }
  }, [userData?.headUrl])

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true
    })

    console.log('result', result)

    if (!result.cancelled) {
      const { uri, base64 } = result
      const image = base64
        ? base64.replace(/^data:image\/[a-z]+;base64,/, '')
        : uri.replace(/^data:image\/[a-z]+;base64,/, '')

      // 上传到 CDN
      const formData = new FormData()
      formData.append('image', image)

      Toast.loading('上传中')

      const { status, data, error } = await axios
        .post(`https://api.imgbb.com/1/upload?key=${IMGBB_KEY}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => res?.data)

      if (status !== 200) {
        Toast.fail(error?.message)
        return
      }

      const { code, msg } = await services.userController.editUsingPOST(
        {},
        {
          headUrl: data.url
        }
      )
      if (code !== 200) {
        Toast.fail('修改头像失败')
        return
      }
      console.log('data.url', data.url)
      setHeadUrl(data.url)
      Toast.success('修改头像成功')
    }
  }

  return (
    <View>
      <WhiteSpace size="lg" />
      <ScrollView
        style={{ backgroundColor: '#f5f5f9' }}
        automaticallyAdjustContentInsets={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <List>
          <Item
            extra={
              <Avatar.Image
                style={{
                  backgroundColor: 'transparent'
                }}
                size={32}
                source={
                  headUrl
                    ? { uri: headUrl }
                    : require('@/assets/defaultAvatar.png')
                }
              />
            }
            arrow="horizontal"
            onPress={pickImage}
          >
            会员头像
          </Item>
          <Item
            extra={userData?.name}
            arrow="horizontal"
            onPress={() => {
              navigation.navigate('ChangeUserName')
            }}
          >
            会员用户名
          </Item>
          {userData?.type === 'company' && (
            <Item
              arrow="horizontal"
              onPress={() => {
                navigation.navigate('ChangeTransferRemark')
              }}
            >
              设置转账提示
            </Item>
          )}
        </List>
      </ScrollView>
    </View>
  )
}

export default Profile
