import { Icon } from '@ant-design/react-native'
import React from 'react'
import {
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View
} from 'react-native'
import { Avatar } from 'react-native-paper'

export interface MenuItemProps {
  onPress: TouchableOpacityProps['onPress']
  title: string
  color: string
  icon: string | React.ReactNode
}

const MenuItem: React.FC<MenuItemProps> = props => {
  const { onPress, title, icon, color } = props

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          borderRadius: 8,
          backgroundColor: '#fff',
          paddingHorizontal: 16,
          paddingVertical: 8,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {React.isValidElement(icon) ? (
            icon
          ) : (
            <Avatar.Icon icon={icon} color={color} size={30} />
          )}
          <Text style={{ marginLeft: 8, fontSize: 16 }}>{title}</Text>
        </View>
        <Icon name="arrow-right" />
      </View>
    </TouchableOpacity>
  )
}

export default MenuItem
