import CompanyBadge from '@/components/CompanyBadge'
import MenuItem from '@/components/MenuItem'
import { AppContext, AuthContext } from '@/core/context'
import { theme } from '@/core/theme'
import { handleCopyData } from '@/core/utils'
import services from '@/services'
import { Icon, WhiteSpace, WingBlank } from '@ant-design/react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React, { useState } from 'react'
import {
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import { Avatar, Caption, Subheading, Title } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

const My: React.FC = () => {
  const isFocused = useIsFocused()
  const navigation = useNavigation()

  const { signOut } = React.useContext(AuthContext)
  const { userData } = React.useContext(AppContext)
  const [moneyVisible, setMoneyVisible] = useState(true)

  const { data: assetInfo, run, cancel } = useRequest(
    services.assetController.getUsingGET1,
    {
      pollingInterval: 8000,
      pollingWhenHidden: false,
      manual: true,
      formatResult: res => res?.data
    }
  )

  React.useEffect(() => {
    isFocused ? run({}) : cancel()
  }, [isFocused])

  const changeEyeIconType = () => {
    setMoneyVisible(prev => !prev)
  }

  const renderItem = ({ item, index }) => {
    return (
      <View style={styles.item} key={index.toString()}>
        <Text style={styles.itemText}>{item.title}</Text>
        <Text style={styles.itemText}>{item.value}</Text>
      </View>
    )
  }

  return (
    <ScrollView>
      <WingBlank style={{ paddingVertical: 30 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('Profile')
            }}
            style={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <Avatar.Image
              style={{
                backgroundColor: 'transparent'
              }}
              size={32}
              source={
                userData?.headUrl
                  ? { uri: userData?.headUrl }
                  : require('@/assets/defaultAvatar.png')
              }
            />
            <View style={{ marginLeft: 8 }}>
              <Subheading style={{ margin: 0 }}>
                {userData.type === 'company' && <CompanyBadge />}
                {userData.name}
              </Subheading>
              <Caption
                style={{ margin: 0 }}
                onPress={() => handleCopyData(String(userData.id))}
              >
                {userData.phoneBikini} (ID: {userData.id}{' '}
                <Icon
                  color={theme.colors.primary}
                  name="copy"
                  size={15}
                />
                )
              </Caption>
            </View>
          </TouchableOpacity>
          <Icon
            name="setting"
            size="md"
            onPress={() => {
              navigation.navigate('Setting')
            }}
            color={theme.colors.secondary}
          />
        </View>
        <WhiteSpace size="lg" />
        <ScrollView>
          <View>
            <View
              style={{
                paddingHorizontal: 16,
                borderRadius: 8,
                backgroundColor: theme.colors.primary
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Title style={{ fontSize: 16, color: '#fff' }}>钱包余额</Title>
                <MaterialIcon
                  size={16}
                  color="#fff"
                  name={moneyVisible ? 'visibility' : 'visibility-off'}
                  onPress={changeEyeIconType}
                />
              </View>
              <FlatList
                style={{ flex: 1, marginVertical: 8 }}
                nestedScrollEnabled
                renderItem={renderItem}
                data={[
                  {
                    title: '总资产（GP）',
                    value: !moneyVisible
                      ? '****'
                      : (assetInfo?.amount + assetInfo?.freezeAmount).toFixed(
                        3
                      ) ?? '--'
                  },
                  {
                    title: '返佣收益（GP）',
                    value: !moneyVisible ? '****' : '+0.00'
                  },
                  {
                    title: '可用总金额（GP）',
                    value: !moneyVisible
                      ? '****'
                      : assetInfo?.amount?.toFixed(3) ?? '--'
                  }
                ]}
                numColumns={2}
              />
            </View>
          </View>
        </ScrollView>
        <WhiteSpace />
        <WhiteSpace />
        {userData.type === 'company' ? (
          <MenuItem
            onPress={() => {
              navigation.navigate('MemUserList')
            }}
            title="预留用户列表"
            icon="account-multiple-check"
            color="#fff"
          />
        ) : null}
        <WhiteSpace />
        {userData.type === 'company' ? (
          <MenuItem
            onPress={() => {
              navigation.navigate('ExchangeList')
            }}
            title="兑换订单列表"
            icon="case-sensitive-alt"
            color="#fff"
          />
        ) : null}
        <WhiteSpace size="lg" />
        <MenuItem
          onPress={() => {
            navigation.navigate('CompanyList', {
              mode: 'select',
              screenName: 'Transfer'
            })
          }}
          title="商户兑换"
          icon="shopping"
          color="#fff"
        />
        <WhiteSpace />
        <MenuItem
          onPress={() => {
            navigation.navigate('Bill')
          }}
          title="账单"
          icon="receipt"
          color="#fff"
        />
        <WhiteSpace />
        <MenuItem
          onPress={() => {
            navigation.navigate('BankCard')
          }}
          title="银行卡"
          icon="credit-card-multiple-outline"
          color="#fff"
        />
        <WhiteSpace /*/>
        <MenuItem
          onPress={() => {
            navigation.navigate('Contact')
          }}
          title="联系人"
          icon="book-account-outline"
          color="#fff"*/
        />
      </WingBlank>
      </ScrollView>
  )
}

const styles = StyleSheet.create({
  item: {
    flex: 1,
    margin: 1,
    height: 60
  },
  itemText: {
    color: '#fff'
  },
  itemInvisible: {
    backgroundColor: 'transparent'
  }
})

export default My
