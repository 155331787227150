import React, { Component } from 'react'
import { Animated, Easing, StyleProp, View, ViewStyle } from 'react-native'

interface DeliveryRuleState {
  translateY: Animated.Value
  data: object[]
}

interface DeliveryRuleProps {
  data: object[]
  renderItem: (e: object, idx: number) => React.ReactNode
  duration?: number
  style?: StyleProp<ViewStyle>
  delay?: number
  toValue?: number
}

class DeliveryRule extends Component<DeliveryRuleProps, DeliveryRuleState> {
  constructor(props) {
    super(props)
    this.state = {
      translateY: new Animated.Value(0),
      data: this.props.data
    }
    this.showHeadBar = this.showHeadBar.bind(this)
  }

  componentDidMount() {
    this.showHeadBar()
  }

  showHeadBar() {
    var array = this.state.data
    if (!Array.isArray(array)) {
      return
    }
    array.push(this.state.data[0])
    Animated.timing(this.state.translateY, {
      toValue: -this.props.toValue || -40, // 40为文本View的高度
      duration: this.props.duration || 1000, // 动画时间
      easing: Easing.linear,
      useNativeDriver: false,
      delay: this.props.delay || 2000 // 文字停留时间
    }).start(() => {
      // -- 删除
      array.shift()
      this.setState(
        {
          data: array
        },
        () => {
          this.setState(
            {
              translateY: new Animated.Value(0)
            },
            () => this.showHeadBar()
          )
        }
      )
    })
  }

  render() {
    return (
      <View
        style={[{ height: 40, overflow: 'hidden' }, this.props.style || null]}
      >
        <Animated.View
          style={{
            transform: [
              {
                translateY: this.state.translateY
              }
            ]
          }}
        >
          {this.state?.data?.map((e, index) => this.props.renderItem(e, index))}
        </Animated.View>
      </View>
    )
  }
}
export default DeliveryRule
