import { startConfirmSecondaryPassword } from '@/core/utils'
import services from '@/services'
import {
  Button,
  Card,
  Icon,
  Modal,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import { Caption, Subheading } from 'react-native-paper'

const Item: React.FC<API.ExchangeList> = ({
  id,
  amount,
  createdAt,
  imgUrl,
  fromId,
  memInfo,
  remark
}) => {
  const isFocused = useIsFocused()
  const [visible, setVisible] = React.useState('')

  const { data: userData } = useRequest(services.userController.getUsingGET2, {
    formatResult: res => res?.data,
    ready: isFocused
  })

  const gpnum = Number.isNaN(Number(amount / userData?.ratioExchange))
    ? amount
    : amount / userData?.ratioExchange

  const AgreeExchange = async eid => {
    const agree = this.state

    if (!userData.secondaryPassword) {
      // 先去设置下二级密码
      Toast.info('未设置二级密码')
      // 跳转到 修改交易密码
      navigation.navigate('ChangeSecondary')
      return
    }
    try {
      const { secondaryPassword } = await startConfirmSecondaryPassword(
        userData
      )
      console.log('secondaryPassword111', secondaryPassword)
      const {
        code,
        msg,
        data
      } = await services.assetController.editExchangePOST(
        {},
        {
          id: eid,
          secondaryPassword: secondaryPassword,
          type: 1
        }
      )

      if (code !== 200) {
        Toast.fail(msg)
        return
      }
      Toast.success('转账成功')
      navigation.navigate('My')
    } catch (_) {}
  }

  const RejectReason = () => {
    Modal.prompt(
      '拒绝原因',
      '',
      reason => RejectExchange(reason),
      'default',
      null,
      ['请填写拒绝原因']
    )
  }
  const RejectExchange = async reason => {
    try {
      const {
        code,
        msg,
        data
      } = await services.assetController.editExchangePOST(
        {},
        {
          id: id,
          type: 2,
          remark: reason
        }
      )

      if (code !== 200) {
        Toast.fail(msg)
        return
      }
      Toast.success('已拒绝')
      navigation.navigate('My')
    } catch (_) {}
  }

  const navigation = useNavigation()
  return (
    <TouchableOpacity
      style={styles.item}
      onPress={() => {
        setVisible(true)
      }}
    >
      <WhiteSpace />
      <Card style={styles.card}>
        <Card.Body style={{ borderWidth: 0 }}>
          <Subheading>用户ID: {fromId}</Subheading>
          <Caption>{createdAt}</Caption>
          <Text numberOfLines={1}>
            请求代币数额:{amount}，约等于GP:{gpnum.toFixed(3)} 备注信息:{remark}
          </Text>
          <Text
            style={{
              marginTop: 8,
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Caption>处理兑换</Caption>
            <Icon size="xxs" name="arrow-right" />
          </Text>
        </Card.Body>
      </Card>
      <WhiteSpace />
      <Modal
        title={'用户ID:' + fromId}
        transparent
        maskClosable
        onClose={() => {
          setVisible(false)
        }}
        visible={visible}
        closable
      >
        <View style={{ paddingVertical: 20, alignItems: 'center' }}>
          <Caption>{createdAt}</Caption>
          <Text style={{ textAlign: 'center', fontSize: 18 }}>
            请求代币数额:{amount}
          </Text>
          <Text style={{ textAlign: 'center', fontSize: 18 }}>
            实兑GP数量:{gpnum.toFixed(3)}
          </Text>
          <Text style={{ textAlign: 'center', fontSize: 18 }}>
            预留信息:{memInfo}
          </Text>
          <Text style={{ textAlign: 'center', fontSize: 18 }}>
            备注信息:{remark}
          </Text>
          {imgUrl ? (
            <Image
              source={{ uri: imgUrl }}
              style={{ width: 100, height: 100 }}
            />
          ) : null}
        </View>
        <Button
          type="primary"
          onPress={() => {
            AgreeExchange(id)
          }}
        >
          同意申请
        </Button>
        <WhiteSpace />
        <Button
          type="warning"
          onPress={() => {
            RejectReason()
          }}
        >
          拒绝申请
        </Button>
      </Modal>
    </TouchableOpacity>
  )
}

const ExchangeList = () => {
  const { data, loading } = useRequest(
    () => services.assetController.ExchangeListGetUsingGET({}),
    {
      formatResult: res => res?.data
    }
  )
  console.log('ExchangeListGetUsingGET1 loading', loading)

  console.log('data', data)

  const renderItem = ({ item }) => <Item {...item} />

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }

  return (
    <WingBlank style={{ flex: 1 }}>
      <FlatList
        data={data}
        contentContainerStyle={{ flexGrow: 1 }}
        ListEmptyComponent={
          <Caption style={{ textAlign: 'center', marginVertical: 8 }}>
            暂无兑换申请
          </Caption>
        }
        renderItem={renderItem}
        keyExtractor={item => `${item?.id}`}
      />
    </WingBlank>
  )
}

const styles = StyleSheet.create({
  item: {
    // marginVertical: 8,
    // marginHorizontal: 16
  },
  card: {
    paddingHorizontal: 16
  },
  title: {
    fontSize: 32
  }
})

export default ExchangeList
