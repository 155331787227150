function hideSensitiveInfo(
  prefixLength: number,
  suffixLength: number,
  optionalInfix: any,
  info: string
) {
  if (!info) {
    return ''
  }
  const prefix = info.slice(0, prefixLength)
  const suffix =
    info.length - prefixLength - suffixLength > 0
      ? info.slice(-suffixLength)
      : ''
  const infixLength =
    info.length - prefixLength - suffixLength < 2
      ? 2
      : info.length - prefixLength - suffixLength + 1
  const infix =
    optionalInfix === null ? Array(infixLength).join('*') : optionalInfix
  return prefix + infix + suffix
}

const _mobile = (infix: any, mobile: string) => {
  if (!mobile) {
    return ''
  }
  // 1. 大陆手机号 11 位
  // 2. 香港、澳门手机号 8 位
  // 3. 台湾手机号 10 位
  // Note: 使用手机号长度判断地区是不严谨的，但想不出更好的方案
  switch (mobile.length) {
    case 11:
      return hideSensitiveInfo(3, 2, infix, mobile)
    case 8:
      return hideSensitiveInfo(2, 2, infix, mobile)
    case 10:
      return hideSensitiveInfo(2, 3, infix, mobile)
    // infix === null 意味着是长显示
    default:
      return mobile
  }
}

const bikini: { mobile: Function } = {
  mobile: _mobile.bind(null, null)
}

export default bikini
