import { noop } from '@/core/utils'
import useLoading from '@/hooks/useLoading'
import services from '@/services'
import { SearchBar } from '@ant-design/react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { ScrollView } from 'react-native'
import { DataTable } from 'react-native-paper'

const Contact = () => {
  const navigation = useNavigation()
  const { params } = useRoute()

  const { data, loading } = useRequest(
    () => services.assetController.contactGetUsingGET({ page: 0, size: 1000 }),
    {
      formatResult: res => res?.data
    }
  )

  const [search, setSearch] = React.useState('')

  useLoading(loading)
  if (loading) {
    return null
  }

  const filterData = data?.filter(item => {
    if (search) {
      return [item.id, item.phone, item.type === 'normal' ? '会员' : '商户']
        .join(',')
        .includes(search)
    }
    return true
  })

  const handleContactSelect = item => {
    navigation.navigate(params?.screenName, {
      ...item
    })
  }

  console.log('Contact params', params, params?.mode === 'select')

  return (
    <ScrollView
      style={{ backgroundColor: '#f5f5f9', flex: 1 }}
      automaticallyAdjustContentInsets={false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <SearchBar placeholder="搜索" onChange={text => setSearch(text)} />
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>账户 ID</DataTable.Title>
          <DataTable.Title>会员/商户</DataTable.Title>
        </DataTable.Header>
        {filterData?.map(item => {
          return (
            <DataTable.Row
              key={`${item.id}`}
              // https://github.com/callstack/react-native-paper/pull/2637
              onPress={
                params?.mode === 'select'
                  ? () => handleContactSelect(item)
                  : noop
              }
              pointerEvents="none"
            >
              <DataTable.Cell>{item.id}</DataTable.Cell>
              <DataTable.Cell>
                {item.type === 'normal' ? '会员' : '商户'}
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>
    </ScrollView>
  )
}

export default Contact
