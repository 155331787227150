import { Toast } from '@ant-design/react-native'
import React from 'react'

export default (loading: boolean) => {
  React.useEffect(() => {
    let loadingIns: number

    if (loading) {
      loadingIns = Toast.loading('加载中')
    } else {
      // @ts-ignore
      if (loadingIns) {
        Toast.remove(loadingIns)
      } else {
        Toast.removeAll()
      }
    }
  }, [loading])

  return null
}
