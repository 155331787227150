import React, { memo } from 'react'
import { Text, View } from 'react-native'

const AreaScreen = () => {
  return (
    <View>
      <Text>请选择国家地区</Text>
    </View>
  )
}

export default memo(AreaScreen)
