import { createContext } from 'react'

export const AuthContext = createContext<any>({})

interface UserData extends API.User {
  phoneBikini: string
}
export const AppContext = createContext<{
  userData: UserData
}>({})
