import { AuthContext } from '@/core/context'
import { getAccessToken, setAccessToken } from '@/network/request'
import Applys from '@/pages/app/Applys'
import BankCard from '@/pages/app/BankCard'
import Bill from '@/pages/app/Bill'
import BindBankCard from '@/pages/app/BindBankCard'
import ChangePassword from '@/pages/app/ChangePassword'
import ChangeSecondary from '@/pages/app/ChangeSecondary'
import ChangeTransferRemark from '@/pages/app/ChangeTransferRemark'
import ChangeUserName from '@/pages/app/ChangeUserName'
import CompanyList from '@/pages/app/CompanyList'
import Contact from '@/pages/app/Contact'
import CustomerService from '@/pages/app/CustomerService'
import Exchange from '@/pages/app/Exchange'
import ExchangeList from '@/pages/app/ExchangeList'
import MemUserList from '@/pages/app/MemUserList'
import Message from '@/pages/app/Message'
import MessageDetail from '@/pages/app/Message/MessageDetail'
import Profile from '@/pages/app/Profile'
import Receipt from '@/pages/app/Receipt'
import ReChange from '@/pages/app/ReChange'
import Scan from '@/pages/app/Scan'
import Setting from '@/pages/app/Setting'
import Transfer from '@/pages/app/Transfer'
import WithDrawal from '@/pages/app/WithDrawal'
import Area from '@/pages/auth/area'
import ForgotPassword from '@/pages/auth/forgot-password'
import Login from '@/pages/auth/login'
import Register from '@/pages/auth/register'
import GoogleAuth from '@/pages/GoogleAuth'
import { Icon, Toast, WingBlank } from '@ant-design/react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { Image, Text } from 'react-native'
import AppRoutes from './app.routes'

const ActionType = {
  RESTORE_TOKEN: 'RESTORE_TOKEN',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT'
}

const SplashScreen = () => (
  <Image
    source={require('@/assets/splash.jpg')}
    style={styles.container}
  ></Image>
)

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: null,
    height: null
    // //不加这句，就是按照屏幕高度自适应
    // //加上这句，就是按照屏幕自适应
    // resizeMode:Image.resizeMode.contain,
    // //去除内部元素的白色背景
    // backgroundColor:'rgba(0,0,0,0)',
  }
}
const Stack = createStackNavigator()

const Routes: React.FC = () => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case ActionType.RESTORE_TOKEN:
          return {
            ...prevState,
            accessToken: action.accessToken,
            isLoading: false
          }
        case ActionType.SIGN_IN:
          return {
            ...prevState,
            isSignout: false,
            accessToken: action.accessToken
          }
        case ActionType.SIGN_OUT:
          return {
            ...prevState,
            isSignedIn: true,
            accessToken: null
          }
      }
    },
    {
      isLoading: true,
      isSignout: false,
      accessToken: null
    }
  )

  React.useEffect(() => {
    const bootstrapAsync = async () => {
      let accessToken
      try {
        accessToken = await getAccessToken()
      } catch (e) {}
      dispatch({
        type: ActionType.RESTORE_TOKEN,
        accessToken
      })
    }

    bootstrapAsync()
  }, [])

  const authContext = React.useMemo(
    () => ({
      state,
      signIn: data => {
        dispatch({
          type: ActionType.SIGN_IN,
          accessToken: data
        })
      },
      signOut: async () => {
        await setAccessToken('')
        dispatch({
          type: ActionType.SIGN_OUT
        })
      },
      signUp: data => {
        dispatch({
          type: ActionType.SIGN_IN,
          accessToken: data
        })
      }
    }),
    []
  )

  return (
    <AuthContext.Provider value={authContext}>
      <NavigationContainer>
        <Stack.Navigator>
          {!state.accessToken ? (
            <>
              <Stack.Screen
                name="SignIn"
                component={Login}
                options={{
                  title: '登录',
                  headerShown: false,
                  animationTypeForReplace: state.isSignout ? 'pop' : 'push'
                }}
              />
              <Stack.Screen
                name="ForgotPassword"
                component={ForgotPassword}
                options={{
                  title: '找回登录密码',
                  headerTitleAlign: 'center',
                  animationTypeForReplace: state.isSignout ? 'pop' : 'push'
                }}
              />
              <Stack.Screen
                name="SignUp"
                component={Register}
                options={{
                  title: '注册',
                  headerTitleAlign: 'center',
                  animationTypeForReplace: state.isSignout ? 'pop' : 'push'
                }}
              />

              <Stack.Screen
                name="Area"
                component={Area}
                options={{
                  title: ''
                }}
              />
            </>
          ) : (
            <>
              <Stack.Screen
                name="Home"
                options={{
                  headerShown: false
                }}
                component={AppRoutes}
              />
              <Stack.Screen
                name="ReChange"
                options={{
                  title: '充值',
                  headerTitleAlign: 'center'
                }}
                component={ReChange}
              />
              <Stack.Screen
                name="Profile"
                options={{
                  title: '会员资料',
                  headerTitleAlign: 'center'
                }}
                component={Profile}
              />
              <Stack.Screen
                name="Setting"
                options={{
                  title: '设置',
                  headerTitleAlign: 'center'
                }}
                component={Setting}
              />
              <Stack.Screen
                name="WithDrawal"
                options={{
                  title: '提现',
                  headerTitleAlign: 'center'
                }}
                component={WithDrawal}
              />
              <Stack.Screen
                name="ChangeSecondary"
                options={{
                  title: '修改二级密码',
                  headerTitleAlign: 'center'
                }}
                component={ChangeSecondary}
              />

              <Stack.Screen
                name="ChangePassword"
                options={{
                  title: '修改密码',
                  headerTitleAlign: 'center'
                }}
                component={ChangePassword}
              />

              <Stack.Screen
                name="Transfer"
                options={{
                  title: '转账',
                  headerTitleAlign: 'center'
                }}
                component={Transfer}
              />
              <Stack.Screen
                name="Exchange"
                options={{
                  title: '兑换',
                  headerTitleAlign: 'center'
                }}
                component={Exchange}
              />
              <Stack.Screen
                name="CompanyList"
                options={{
                  title: '商户列表',
                  headerTitleAlign: 'center',
                  headerRight: () => (
                    <WingBlank>
                      <Text onPress={() => Toast.info('暂未开放', 1)}>
                        商户申请
                      </Text>
                    </WingBlank>
                  )
                }}
                component={CompanyList}
              />
              <Stack.Screen
                name="Contact"
                options={{
                  title: '历史联系人',
                  headerTitleAlign: 'center'
                }}
                component={Contact}
              />
              <Stack.Screen
                name="Applys"
                options={{
                  title: '充提账单',
                  headerTitleAlign: 'center'
                }}
                component={Applys}
              />
              <Stack.Screen
                name="Scan"
                options={{
                  headerShown: false
                }}
                component={Scan}
              />
              <Stack.Screen
                name="CustomerService"
                options={{
                  title: '客服',
                  headerTitleAlign: 'center'
                }}
                component={CustomerService}
              />
              <Stack.Screen
                name="BankCard"
                options={({ navigation }) => ({
                  title: '银行卡列表',
                  headerTitleAlign: 'center',
                  headerRight: () => (
                    <WingBlank>
                      <Icon
                        onPress={() => {
                          navigation.navigate('BindBankCard')
                        }}
                        color="#000"
                        name="plus"
                      />
                    </WingBlank>
                  )
                })}
                component={BankCard}
              />
              <Stack.Screen
                name="BindBankCard"
                options={{
                  title: '添加银行卡',
                  headerTitleAlign: 'center'
                }}
                component={BindBankCard}
              />
              <Stack.Screen
                name="Receipt"
                options={{
                  title: '收款',
                  headerTintColor: '#fff',
                  cardStyle: {
                    backgroundColor: '#0050b3'
                  },
                  headerStyle: {
                    backgroundColor: '#0050b3',
                    borderBottomWidth: 0
                  },
                  cardShadowEnabled: false,
                  headerTitleAlign: 'center'
                }}
                component={Receipt}
              />
              <Stack.Screen
                name="Message"
                options={{
                  title: '消息',
                  headerTitleAlign: 'center'
                }}
                component={Message}
              />
              <Stack.Screen
                name="MessageDetail"
                options={{
                  title: '',
                  headerTitleAlign: 'center'
                }}
                component={MessageDetail}
              />

              <Stack.Screen
                name="Bill"
                options={({ navigation }) => ({
                  title: '账单',
                  headerTitleAlign: 'center',
                  headerRight: () => (
                    <WingBlank>
                      <Text onPress={() => navigation.navigate('Applys')}>
                        充提账单
                      </Text>
                    </WingBlank>
                  )
                })}
                component={Bill}
              />

              <Stack.Screen
                name="MemUserList"
                options={{
                  title: '预留信息用户列表',
                  headerTitleAlign: 'center'
                }}
                component={MemUserList}
              />
              <Stack.Screen
                name="ExchangeList"
                options={{
                  title: '兑换订单列表',
                  headerTitleAlign: 'center'
                }}
                component={ExchangeList}
              />
              <Stack.Screen
                name="ChangeUserName"
                options={{
                  title: '修改用户名',
                  headerTitleAlign: 'center'
                }}
                component={ChangeUserName}
              />
              <Stack.Screen
                name="ChangeTransferRemark"
                options={{
                  title: '修改转账提示',
                  headerTitleAlign: 'center'
                }}
                component={ChangeTransferRemark}
              />
              <Stack.Screen
                name="GoogleAuth"
                options={{
                  title: '绑定谷歌验证器',
                  headerTitleAlign: 'center'
                }}
                component={GoogleAuth}
              />
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </AuthContext.Provider>
  )
}

export default Routes
