import services from '@/services'
import {
  Card,
  Icon,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { FlatList, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { Caption, Subheading } from 'react-native-paper'

const wait = timeout => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout)
  })
}

const Item: React.FC<API.Message> = ({ title, msg, createdAt }) => {
  const navigation = useNavigation()
  return (
    <TouchableOpacity
      style={styles.item}
      onPress={() => {
        navigation.navigate('MessageDetail', {
          title,
          description: msg,
          date: createdAt
        })
      }}
    >
      <WhiteSpace />
      <Card style={styles.card}>
        <Card.Body style={{ borderWidth: 0 }}>
          <Subheading>{title}</Subheading>
          <Caption>{createdAt}</Caption>
          <Text numberOfLines={1}>{msg.replace(/<[^>]+>/g, '')}</Text>
          <Text
            style={{
              marginTop: 8,
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Caption>阅读全文</Caption>
            <Icon size="xxs" name="arrow-right" />
          </Text>
        </Card.Body>
      </Card>
      <WhiteSpace />
    </TouchableOpacity>
  )
}

const Message = () => {
  const route = useRoute()
  // 默认私有消息
  const { msgType = 'private' } = route.params || {}

  const { data, loading } = useRequest(
    () =>
      services.userController.messageGetUsingGET1({
        msgType,
        page: 0,
        size: 1000
      }),
    {
      formatResult: res => res?.data
    }
  )
  console.log('messageGetUsingGET1 loading', loading)
  const [refreshing, setRefreshing] = React.useState<boolean | undefined>()

  React.useEffect(() => {
    ;(async () => {
      // 清除 messageStore
      if (msgType === 'private') {
        await AsyncStorage.removeItem('privateMessages')
      }
    })()
  }, [])

  React.useEffect(() => {
    setRefreshing(!!loading)
  }, [loading])

  console.log('data', data)

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)

    wait(2000).then(() => setRefreshing(false))
  }, [])

  const renderItem = ({ item }) => <Item {...item} />

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }

  return (
    <WingBlank style={{ flex: 1 }}>
      <FlatList
        data={data}
        contentContainerStyle={{ flexGrow: 1 }}
        ListEmptyComponent={
          <Caption style={{ textAlign: 'center', marginVertical: 8 }}>
            暂无消息
          </Caption>
        }
        renderItem={renderItem}
        refreshing={refreshing}
        onRefresh={onRefresh}
        keyExtractor={item => `${item?.id}`}
      />
    </WingBlank>
  )
}

const styles = StyleSheet.create({
  item: {
    // marginVertical: 8,
    // marginHorizontal: 16
  },
  card: {
    paddingHorizontal: 16
  },
  title: {
    fontSize: 32
  }
})

export default Message
