import { PasswordInputText } from '@/components/TextInput'
import services from '@/services'
import { Toast, WhiteSpace, WingBlank } from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { View } from 'react-native'
import { Button } from 'react-native-paper'

const ChangeSecondary: React.FC<any> = () => {
  const navigation = useNavigation()
  const { data: userData, loading } = useRequest(
    services.userController.getUsingGET2,
    {
      formatResult: res => res?.data
    }
  )
  const isEdit = !!userData?.secondaryPassword

  const [secondaryPassword, setSecondaryPassword] = React.useState({
    value: '',
    error: ''
  })
  const [oldSecondaryPassword, setOldSecondaryPassword] = React.useState({
    value: '',
    error: ''
  })
  const [
    confirmSecondaryPassword,
    setConfirmSecondaryPassword
  ] = React.useState({ value: '', error: '' })

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }

  console.log('isEdit', isEdit)

  const handleChangeSecondary = async () => {
    if (secondaryPassword?.error) {
      return
    }
    const {
      code,
      msg,
      data
    } = await services.userController.bindSecondaryPasswordUsingPOST(
      {},
      {
        secondaryPassword: secondaryPassword.value
      }
    )
    console.log('data', data)
    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    Toast.success('修改成功')
    navigation.goBack()
  }

  const handleEditSecond = async () => {
    if (secondaryPassword?.error) {
      return
    }
    const {
      code,
      msg
    } = await services.userController.editSecondaryPasswordUsingPOST(
      {},
      {
        newSecondaryPassword: secondaryPassword.value,
        oldSecondaryPassword: oldSecondaryPassword.value
      }
    )
    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    Toast.success('修改成功')
    navigation.goBack()
  }

  return (
    <View>
      <WingBlank>
        <View style={{ width: '100%' }}>
          {isEdit && (
            <PasswordInputText
              label="旧密码"
              keyboardType="number-pad"
              placeholder="请填写旧密码，必须为 6 位数"
              maxLength={6}
              onChangeText={text => setOldSecondaryPassword({ value: text })}
            />
          )}
          <PasswordInputText
            label="新密码"
            keyboardType="number-pad"
            placeholder="请填写新密码，必须为 6 位数"
            maxLength={6}
            onChangeText={text => setSecondaryPassword({ value: text })}
          />
          <PasswordInputText
            label="确认密码"
            keyboardType="number-pad"
            maxLength={6}
            placeholder="请再次填写密码"
            onChangeText={text => {
              setConfirmSecondaryPassword(prev => ({
                ...prev,
                error:
                  Number(text) !== Number(secondaryPassword.value)
                    ? '两次密码不一致'
                    : ''
              }))
            }}
            errorText={confirmSecondaryPassword.error}
          />
          {/* <SMSTextInput onChangeText={} /> */}
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <Button
            mode="contained"
            onPress={isEdit ? handleEditSecond : handleChangeSecondary}
          >
            确认修改
          </Button>
        </View>
      </WingBlank>
    </View>
  )
}

export default ChangeSecondary
