import TextInput from '@/components/TextInput'
import services from '@/services'
import { Toast, WhiteSpace, WingBlank } from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { View } from 'react-native'
import { Button } from 'react-native-paper'

const ChangeTransferRemark: React.FC<any> = () => {
  const navigation = useNavigation()
  const { data: userData, loading } = useRequest(
    services.userController.getUsingGET2,
    {
      formatResult: res => res?.data
    }
  )

  const [companyName, setCompanyName] = React.useState({
    value: '',
    error: ''
  })
  const [transferRemark, setTransferRemark] = React.useState({
    value: '',
    error: ''
  })
  const [ratioTransfer, setRatioTransfer] = React.useState({
    value: '',
    error: ''
  })
  const [ratioExchange, setRatioExchange] = React.useState({
    value: '',
    error: ''
  })

  React.useEffect(() => {
    if (userData?.companyName) {
      setCompanyName(prev => ({ ...prev, value: userData.companyName }))
    }
    if (userData?.transferRemark) {
      setTransferRemark(prev => ({ ...prev, value: userData.transferRemark }))
    }
    if (userData?.ratioTransfer) {
      setRatioTransfer(prev => ({ ...prev, value: userData.ratioTransfer }))
    }
    if (userData?.ratioExchange) {
      setRatioExchange(prev => ({ ...prev, value: userData.ratioExchange }))
    }
  }, [userData])

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }
  const handleChangeTransferRemark = async () => {
    const { code, msg, data } = await services.userController.editUsingPOST(
      {},
      {
        companyName: companyName.value,
        transferRemark: transferRemark.value,
        ratio_transfer: ratioTransfer.value,
        ratio_exchange: ratioExchange.value
      }
    )
    console.log('data', data)
    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    Toast.success('修改成功')
    // 成功了
    navigation.goBack()
  }

  return (
    <View>
      <WingBlank>
        <View style={{ width: '100%' }}>
          <TextInput
            label="商家账号"
            placeholder="请输入平台商家账号"
            value={companyName.value}
            onChangeText={text =>
              setCompanyName(prev => ({ ...prev, value: text }))
            }
          />
          <TextInput
            label="转账提示"
            placeholder="请输入转账提示"
            value={transferRemark.value}
            onChangeText={text =>
              setTransferRemark(prev => ({ ...prev, value: text }))
            }
          />
          <TextInput
            label="转入比例"
            placeholder="填写99即为1:99"
            value={ratioTransfer.value}
            onChangeText={text =>
              setRatioTransfer(prev => ({ ...prev, value: text }))
            }
          />
          <TextInput
            label="兑换比例"
            placeholder="填写101即为1:101"
            value={ratioExchange.value}
            onChangeText={text =>
              setRatioExchange(prev => ({ ...prev, value: text }))
            }
          />
          {/* <SMSTextInput onChangeText={} /> */}
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <Button mode="contained" onPress={handleChangeTransferRemark}>
            确认修改
          </Button>
        </View>
      </WingBlank>
    </View>
  )
}

export default ChangeTransferRemark
