import CreditCard from '@/components/CreditCard'
import useLoading from '@/hooks/useLoading'
import services from '@/services'
import {
  Button,
  Modal,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import {
  useFocusEffect,
  useNavigation,
  useRoute
} from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { FlatList, Image, TouchableOpacity, View } from 'react-native'
import { Caption } from 'react-native-paper'

export interface BankCardProps {}

const BankCard: React.FC<BankCardProps> = props => {
  const navigation = useNavigation()
  const route = useRoute()
  const { params } = route

  console.log('paramsparams', params)

  const { data, loading, run, cancel } = useRequest(
    () =>
      services.userController.bankGetUsingGET({
        page: 0,
        size: 1000
      }),
    {
      manual: true,
      formatResult: res => res?.data
    }
  )

  useFocusEffect(
    React.useCallback(() => {
      run()
      return cancel
    }, [])
  )

  useLoading(loading)

  const isEmpty = Array.isArray(data) && data.length === 0

  const handleBindBankCard = () => {
    navigation.navigate('BindBankCard')
  }

  if (isEmpty) {
    return (
      <View
        style={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <View style={{ marginTop: -100, alignItems: 'center' }}>
          <Image
            style={{ width: 200, height: 200, resizeMode: 'contain' }}
            source={require('@/assets/empty.png')}
          />
          <Caption style={{ fontSize: 16 }}>
            快速绑定银行卡，充值提现更便捷
          </Caption>
          <WhiteSpace size="lg" />
          <Button
            type="primary"
            style={{ width: 200 }}
            onPress={handleBindBankCard}
          >
            添加银行卡
          </Button>
        </View>
      </View>
    )
  }

  const unbindBankCard = userBankId => {
    Modal.alert('温馨提示', '是否删除银行卡', [
      { text: '取消', onPress: () => console.log('cancel'), style: 'cancel' },
      {
        text: '确定',
        onPress: async () => {
          const result = await services.userController.unbindBankUsingPOST(
            {},
            { userBankId }
          )
          if (result?.code === 200) {
            Toast.success('删除成功')
            run()
            return
          }
          Toast.fail(result.msg)
        }
      }
    ])
  }

  const selectBankCard = item => {
    navigation.navigate(params?.screenName, {
      item
    })
  }

  return (
    <WingBlank style={{ flex: 1 }}>
      <FlatList
        style={{ paddingVertical: 16 }}
        contentContainerStyle={{ flexGrow: 1 }}
        data={data?.sort((a, b) => b?.id - a?.id)}
        keyExtractor={item => `${item?.id}`}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              key={item.id}
              onPress={() =>
                params?.mode === 'select'
                  ? selectBankCard(item)
                  : unbindBankCard(item.id)
              }
            >
              <CreditCard
                style={{ width: '100%' }}
                name={item.name + ' ' + item.bankName}
                country={item.country}
                suffix={item?.card?.slice(-4)}
                prefix={item?.card?.slice(0, 4)}
              />
              <WhiteSpace />
            </TouchableOpacity>
          )
        }}
      />
    </WingBlank>
  )
}

export default BankCard
