import antdDefaultTheme from '@ant-design/react-native/es/style/themes/default'
import { DefaultTheme } from 'react-native-paper'

export const theme = {
  ...DefaultTheme,
  dark: false,
  mode: 'exact',
  colors: {
    ...DefaultTheme.colors,
    primary: '#108ee9',
    secondary: '#414757',
    error: '#ff4d4f'
  }
}

export const antdTheme = {
  ...antdDefaultTheme,
  brand_primary: '#108ee9',
  content: {
    maxWidth: '100%'
  }
}
