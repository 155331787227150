import { theme } from '@/core/theme'
import { handleCopyData } from '@/core/utils'
import services from '@/services'
import {
  InputItem as AntdTextInput,
  Steps,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Badge, Button, TextInput } from 'react-native-paper'
import QRCode from 'react-native-qrcode-svg'

const { Step } = Steps

const GoogleAuth: React.FC<any> = props => {
  const navigation = useNavigation()
  const [code, setCode] = React.useState<number>()
  const { data, loading } = useRequest(
    services.userController.getGoogleKeyUsingGET,
    {
      formatResult: res => res?.data
    }
  )

  const handleGoogleAuth = async () => {
    const result = await services.userController.bindGoogleAuthUsingPOST(
      {},
      {
        code: Number(code),
        key: data
      }
    )
    console.log('handleGoogleAuth result', result)
    if (result?.code !== 200) {
      Toast.fail(result.msg)
      return
    }
    Toast.success('绑定成功')
    // 成功了
    navigation.goBack()
  }
  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }
  return (
    <View>
      <View
        style={{
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <View style={styles.noticeView}>
          <WingBlank>
            <Text style={styles.noticeText}>
              TIPS: 为了您的账号安全，建议您使用谷歌验证器绑定账户
            </Text>
            <Text style={styles.noticeText}>ID 实时验证码</Text>
          </WingBlank>
        </View>
        <WhiteSpace size="lg" />
        <WingBlank>
          <Steps status="finish">
            <Step
              title={
                <Button
                  mode="contained"
                  onPress={() => {
                    // TODO
                  }}
                >
                  下载"谷歌验证器"APP
                </Button>
              }
              renderIcon={() => (
                <Badge
                  style={{
                    backgroundColor: theme.colors.primary
                  }}
                >
                  1
                </Badge>
              )}
            />
            <Step
              title={
                <View style={{ flex: 1, maxWidth: '100%' }}>
                  <Text style={{ fontSize: 12 }}>
                    点击谷歌验证器APP右下角"+"图标进入菜单
                  </Text>
                  <Text style={{ fontSize: 12 }}>
                    复制以下密钥并在谷歌验证器APP中添加输入
                  </Text>
                  <WhiteSpace />
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex: 1 }}>
                      <TextInput
                        style={{ height: 32 }}
                        editable={false}
                        disabled
                        value={data}
                      />
                    </View>
                    <Button
                      style={styles.copyBtn}
                      compact
                      onPress={() => handleCopyData(data)}
                      mode="text"
                    >
                      复制
                    </Button>
                  </View>
                  <WhiteSpace size="lg" />
                  <View
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Text style={{ fontSize: 12 }}>
                      或扫描下方二维码生成账号验证码
                    </Text>
                    <WhiteSpace />
                    <QRCode value={data} />
                  </View>
                  <WhiteSpace size="lg" />
                </View>
              }
              renderIcon={() => (
                <Badge style={{ backgroundColor: theme.colors.primary }}>
                  2
                </Badge>
              )}
            />
            <Step
              title={
                <View style={{ flexDirection: 'row', marginLeft: -12 }}>
                  <AntdTextInput
                    placeholder="请输入谷歌验证码"
                    labelPosition="left"
                    type="number"
                    onChangeText={text => setCode(text)}
                  >
                    <Text style={{ fontSize: 12 }}>绑定谷歌验证码</Text>
                  </AntdTextInput>
                </View>
              }
              renderIcon={() => (
                <Badge style={{ backgroundColor: theme.colors.primary }}>
                  3
                </Badge>
              )}
            />
          </Steps>
        </WingBlank>
      </View>
      <View style={{ alignSelf: 'center' }}>
        <Button mode="contained" onPress={handleGoogleAuth}>
          绑定谷歌验证码
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  noticeView: {
    backgroundColor: '#e6f7ff',
    paddingVertical: 8
  },
  noticeText: {
    fontSize: 12,
    color: '#1890ff'
  },
  copyBtn: {
    // position: 'absolute'
    // top: 33,
    // right: 0
  }
})

export default GoogleAuth
