import Background from '@/components/Background'
import Button from '@/components/Button'
import Header from '@/components/Header'
import Logo from '@/components/Logo'
import registerForPushNotificationsAsync from '@/components/PushNotification'
import { AreaInput, PasswordInputText } from '@/components/TextInput'
import { AuthContext } from '@/core/context'
import { theme } from '@/core/theme'
// import { emailValidator, passwordValidator } from '@/core/utils'
import { setAccessToken } from '@/network/request'
import services from '@/services'
import { useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React, { memo, useState } from 'react'
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'

const LoginScreen = () => {
  const authContext = React.useContext(AuthContext)
  const navigation = useNavigation()
  const { run: userLogin, loading: userLoginLoading } = useRequest(
    services.userController.loginUsingPOST1,
    {
      manual: true
    }
  )

  const [area, setArea] = useState({ value: '+86', error: '' })
  const phoneInput = React.useRef<AreaInput>(null)
  const [countryCode, setCountryCode] = useState('')

  const [name, setName] = useState({ value: '', error: '' })
  const [password, setPassword] = useState({ value: '', error: '' })

  const handleLoginPressed = async () => {
    if (area.error || name.error || password.error) {
      return
    }
    const { code, msg, data } = await userLogin(
      {},
      {
        area: area.value,
        name: name.value,
        password: password.value,
        os: Platform.OS,
        language_type: 1,
        pushtoken:
          Platform.OS === 'web' ? '' : await registerForPushNotificationsAsync()
      }
    )
    if (code === 200) {
      await setAccessToken(data as string)
      // 校验成功
      authContext.signIn(data)
    } else {
      setPassword({
        ...password,
        error: msg
      })
    }
    console.log('resultresult', code, msg, data)

    // navigation.navigate('Dashboard')
  }

  return (
    <Background>
      <Logo />

      <Header>Game Pay</Header>

      <AreaInput
        withShadow
        ref={phoneInput}
        defaultCode="CN"
        onChangeText={text => setName({ value: text, error: '' })}
        onChangeCountry={country => {
          setArea({ value: '+' + country.callingCode })
        }}
        placeholder="请输入手机号码"
        layout="first"
      ></AreaInput>

      {/* <TouchableOpacity onPress={() => alert(1)}> */}
      {/* <Pressable
        onPress={() => {
          console.log('onPress')
          setAreaVisible(true)
        }}
      > */}
      <PasswordInputText
        label="密码"
        returnKeyType="done"
        value={password.value}
        onChangeText={text => setPassword({ value: text, error: '' })}
        error={!!password.error}
        errorText={password.error}
      />

      <View style={styles.forgetrow}>
        <TouchableOpacity
          style={styles.forgotPassword}
          onPress={() => navigation.navigate('ForgotPassword')}
        >
          <Text style={styles.label}>忘记密码</Text>
        </TouchableOpacity>
      </View>

      <Button
        mode="contained"
        loading={userLoginLoading}
        onPress={handleLoginPressed}
      >
        登录
      </Button>

      <View style={styles.row}>
        <Text style={styles.label}>没有账号? </Text>
        <TouchableOpacity onPress={() => navigation.navigate('SignUp')}>
          <Text style={styles.link}>快速注册</Text>
        </TouchableOpacity>
      </View>
    </Background>
  )
}

const styles = StyleSheet.create({
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24
  },
  flag: {
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: 24
  },
  forgetrow: {
    width: '100%',
    flexDirection: 'row'
  },
  row: {
    flexDirection: 'row',
    marginTop: 4
  },
  label: {
    color: theme.colors.secondary
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5FCFF'
  },
  titleText: {
    color: '#000',
    fontSize: 25,
    marginBottom: 25,
    fontWeight: 'bold'
  },
  picker: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
    color: '#000',
    width: '100%',
    display: 'none'
  }
})

export default memo(LoginScreen)
