import { noop } from '@/core/utils'
import useLoading from '@/hooks/useLoading'
import services from '@/services'
import { SearchBar, Toast } from '@ant-design/react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { ScrollView } from 'react-native'
import { DataTable } from 'react-native-paper'

const CompanyList = () => {
  const navigation = useNavigation()
  const { params } = useRoute()

  const { data, loading } = useRequest(
    () => services.userController.getCompanyUsingGET({ page: 0, size: 10000 }),
    {
      formatResult: res => res?.data
    }
  )

  const [search, setSearch] = React.useState('')

  useLoading(loading)
  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }

  const filterData = data?.filter(item => {
    if (search) {
      return [item.id, item.phone, item.type === 'normal' ? '会员' : '商户']
        .join(',')
        .includes(search)
    }
    return true
  })

  const handleCompanySelect = (item: any) => {
    console.log('1212')
    navigation.navigate(params?.screenName, {
      ...item
    })
  }

  return (
    <ScrollView
      style={{ backgroundColor: '#f5f5f9' }}
      automaticallyAdjustContentInsets={false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <SearchBar placeholder="搜索" onChange={text => setSearch(text)} />
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>账户 ID</DataTable.Title>
          <DataTable.Title>账户名称</DataTable.Title>
          <DataTable.Title>会员/商户</DataTable.Title>
        </DataTable.Header>
        {filterData?.map(item => {
          return (
            <DataTable.Row
              key={`${item.id}`}
              // https://github.com/callstack/react-native-paper/pull/2637
              onPress={
                params?.mode === 'select'
                  ? () => handleCompanySelect(item)
                  : noop
              }
              pointerEvents="none"
            >
              <DataTable.Cell>{item.id}</DataTable.Cell>
              <DataTable.Cell>{item.name}</DataTable.Cell>
              <DataTable.Cell>
                {item.type === 'normal' ? '会员' : '商户'}
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>
    </ScrollView>
  )
}

export default CompanyList
