// @ts-ignore
/* eslint-disable */
import { request } from '@/network/request';

/** 获得管理员信息 GET /admin */
export async function getUsingGET(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin', {
    method: 'GET',
    headers: {},
    params: { ...params },
    ...(options || {}),
  });
}

/** 处理申请 POST /admin/apply_deal */
export async function applyDealUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.ApplyDealDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/apply_deal', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 获得申请 GET /admin/apply_get */
export async function applyGetUsingGET(
  params: {
    // query
    /** type */
    type?: string;
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/apply_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改用户资产 POST /admin/asset_edit */
export async function assetEditUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.AssetEditDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/asset_edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 获得用户资产 GET /admin/asset_get */
export async function assetGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListAsset_>('/admin/asset_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获得用户账单 GET /admin/asset_log_get */
export async function assetLogGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListAssetLog_>('/admin/asset_log_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 桌面 GET /admin/dashboard */
export async function dashboardUsingGET(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/dashboard', {
    method: 'GET',
    headers: {},
    params: { ...params },
    ...(options || {}),
  });
}

/** 保存信息 POST /admin/message_add */
export async function messageAddUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.MessageAddDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/message_add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 获得用户列表 POST /admin/message_edit */
export async function userEditUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.MessageEditDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListUser_>('/admin/message_edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 获得信息列表 GET /admin/message_get */
export async function messageGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListMessage_>('/admin/message_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 管理员登陆 POST /admin/ops_login */
export async function loginUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.LoginDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/ops_login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 增加支付配置 POST /admin/pay_config_add */
export async function payConfigAddUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.PayConfigAddDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/pay_config_add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 删除支付配置 POST /admin/pay_config_del */
export async function payConfigDelUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.PayConfigDelDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/pay_config_del', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 修改支付配置 GET /admin/pay_config_edit */
export async function payConfigEditUsingGET(
  params: {
    // query
    /** payConfigId */
    payConfigId?: number;
    /** name */
    name?: string;
    /** min */
    min?: number;
    /** max */
    max?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/pay_config_edit', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获得支付配置 GET /admin/pay_config_get */
export async function payConfigGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/pay_config_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 增加系统配置 POST /admin/sys_config_add */
export async function sysConfigAddUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.SysConfigAddDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/sys_config_add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 修改系统配置 POST /admin/sys_config_edit */
export async function sysConfigEditUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.SysConfigEditDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/sys_config_edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 修改系统配置 GET /admin/sys_config_get */
export async function sysConfigGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseObject_>('/admin/sys_config_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获得用户资产 GET /admin/transfer_log_get */
export async function transferLogGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListTransferLog_>('/admin/transfer_log_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获得用户列表 GET /admin/user_addr_get */
export async function userAddrGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListUserAddr_>('/admin/user_addr_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获得用户列表 GET /admin/user_bank_get */
export async function userBankGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListUserBank_>('/admin/user_bank_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获得用户列表 POST /admin/user_edit */
export async function userEditUsingPOST1(
  params: {
    // header
    /** access_token */
    access_token?: string;
  },
  body: API.UserEditDTO,
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListUser_>('/admin/user_edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    data: body,
    ...(options || {}),
  });
}

/** 获得用户列表 GET /admin/user_get */
export async function userGetUsingGET(
  params: {
    // query
    /** page */
    page?: number;
    /** size */
    size?: number;
    // header
    /** access_token */
    access_token?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.GamePayResponseListUser_>('/admin/user_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
