import { PasswordInputText } from '@/components/TextInput'
import services from '@/services'
import { Toast, WhiteSpace, WingBlank } from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { View } from 'react-native'
import { Button } from 'react-native-paper'

const ChangePassword: React.FC<any> = () => {
  const navigation = useNavigation()
  const { data: userData, loading } = useRequest(
    services.userController.getUsingGET2,
    {
      formatResult: res => res?.data
    }
  )

  const [Password, setPassword] = React.useState({
    value: '',
    error: ''
  })
  const [oldPassword, setOldPassword] = React.useState({
    value: '',
    error: ''
  })
  const [confirmPassword, setConfirmPassword] = React.useState({
    value: '',
    error: ''
  })

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }

  const handleEditPassword = async () => {
    if (Password?.error) {
      return
    }
    const { code, msg } = await services.userController.editPasswordUsingPOST(
      {},
      {
        newPassword: Password.value,
        oldPassword: oldPassword.value
      }
    )
    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    Toast.success('修改成功')
    navigation.goBack()
  }

  return (
    <View>
      <WingBlank>
        <View style={{ width: '100%' }}>
          <PasswordInputText
            label="旧密码"
            placeholder="请填写旧密码"
            onChangeText={text => setOldPassword({ value: text })}
          />

          <PasswordInputText
            label="新密码"
            placeholder="请填写新密码"
            onChangeText={text => setPassword({ value: text })}
          />
          <PasswordInputText
            label="确认密码"
            placeholder="请再次填写密码"
            onChangeText={text => {
              setConfirmPassword(prev => ({
                ...prev,
                error:
                  Number(text) !== Number(Password.value)
                    ? '两次密码不一致'
                    : ''
              }))
            }}
            errorText={confirmPassword.error}
          />
          {/* <SMSTextInput onChangeText={} /> */}
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <Button mode="contained" onPress={handleEditPassword}>
            确认修改
          </Button>
        </View>
      </WingBlank>
    </View>
  )
}

export default ChangePassword
