import { AppContext } from '@/core/context'
import { theme } from '@/core/theme'
import { bikini } from '@/core/utils'
import { logout } from '@/network/request'
import Home from '@/pages/app/Home'
import My from '@/pages/app/My'
import services from '@/services'
import { Modal, Toast } from '@ant-design/react-native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'

const Tab = createBottomTabNavigator()

const AppRoutes: React.FC = () => {
  const navigation = useNavigation()

  const { data: result, loading } = useRequest(
    services.userController.getUsingGET2,
    {
      onSuccess: async result => {
        // token 可能失效
        if (result?.code !== 200) {
          Toast.fail(data.msg)
          await logout()
          return
        }
        // 未绑定 Google 身份验证，提示绑定
        if (!result?.data?.secondaryPassword) {
          Modal.alert('温馨提示', '请绑定二级密码用于您的资金操作', [
            {
              text: '暂不绑定',
              onPress: () => console.log('cancel'),
              style: 'cancel'
            },
            {
              text: '去绑定',
              onPress: () => navigation.navigate('ChangeSecondary')
            }
          ])
          console.log('Modal.alert', Modal.alert)
        }
      }
    }
  )

  const { data } = result ?? {}

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }
  console.log('userData', data)

  return (
    <AppContext.Provider
      value={{
        userData: {
          ...data,
          phoneBikini: bikini.mobile(data?.phone)
        }
      }}
    >
      <Tab.Navigator
        initialRouteName="Home"
        tabBarOptions={{
          activeTintColor: theme.colors.primary
        }}
      >
        <Tab.Screen
          name="Home"
          component={Home}
          options={{
            tabBarLabel: '首页',
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons name="home" color={color} size={size} />
            )
          }}
        />

        <Tab.Screen
          name="My"
          component={My}
          options={{
            tabBarLabel: '我的',
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="account"
                color={color}
                size={size}
              />
            )
          }}
        />
      </Tab.Navigator>
    </AppContext.Provider>
  )
}

export default AppRoutes
