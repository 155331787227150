import { noop } from '@/core/utils'
import services from '@/services'
import { Toast } from '@ant-design/react-native'
import { useCountDown } from 'ahooks'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import TextInput from './TextInput'

interface SmsTextInputProps {
  onChangeText: any
  smsOpts?: API.SendSMSDTO
}

const SmsTextInput: React.FC<SmsTextInputProps> = props => {
  const { style, onChangeText } = props
  const [countdown, setTargetDate] = useCountDown()

  const handleSendSms = async () => {
    let smsOpts = props?.smsOpts
    if (!smsOpts) {
      // 需要去获取下用户信息
      const { data } = await services.userController.getUsingGET2({})
      smsOpts = {
        area: data?.area,
        to: data?.phone
      }
    }

    console.log('smsOpts', smsOpts)
    if (smsOpts.area && smsOpts.to) {
      setTargetDate(Date.now() + 60 * 1000)
      const result = await services.userController.sendSmsUsingPOST(
        {},
        {
          ...smsOpts
        }
      )
      console.log('result', result)
      if (result?.code !== 200) {
        Toast.fail(result?.msg)
        return
      }
      // 60s 时间
    } else {
      Toast.fail('请输入手机号或区号')
    }
  }

  return (
    <View
      style={[
        {
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'nowrap',
          justifyContent: 'center'
        },
        StyleSheet.flatten(style)
      ]}
    >
      <View style={{ flex: 2 }}>
        <TextInput
          dense
          label="手机验证码"
          onChangeText={onChangeText}
          placeholder="请输入手机验证码"
        />
      </View>
      <View style={{ flex: 1, alignItems: 'center' }}>
        <Text onPress={countdown !== 0 ? noop : handleSendSms}>
          {countdown === 0 ? '获取验证码' : `${Math.round(countdown / 1000)}s`}
        </Text>
      </View>
    </View>
  )
}

export default SmsTextInput
