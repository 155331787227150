import {
  startConfirmSecondaryPassword,
  startSecondaryPrompt
} from '@/core/utils'
import services from '@/services'
import {
  Button as AntdButton,
  InputItem,
  List,
  Picker,
  Toast,
  WhiteSpace
} from '@ant-design/react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { ScrollView } from 'react-native'

const Tab = createMaterialTopTabNavigator()

const CardScreen = ({ params, rateMap, balance, navigation }) => {
  const [amount, setAmount] = React.useState({ value: 0, error: '' })
  const [currency, setCurrency] = React.useState(['CNY'])

  const { bankName, card, name } = params?.item || {}

  console.log('params', params)

  const handleWithDrawal = async () => {
    if (Number.isNaN(Number(amount.value)) || Number(amount.value) <= 0) {
      // 异常值
      Toast.fail('请输入正确的金额')
      return false
    }

    const {
      data: { data: userData }
    } = (await services.userController.getUsingGET2({})) || {}

    const { secondaryPassword } = await startConfirmSecondaryPassword(userData)
    const {
      data,
      msg,
      code
    } = await services.userController.applyWithdrawBankUsingPOST(
      {},
      {
        amount: amount.value,
        bank: bankName,
        currency: currency[0],
        to: name + '\r\n' + card,
        secondaryPassword
      }
    )
    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    Toast.success('提现申请已提交')
    navigation.goBack()
  }

  const money = Number.isNaN(
    Number(amount.value * rateMap?.['CNY'][currency?.[0]])
  )
    ? 0
    : amount.value * rateMap?.['CNY'][currency?.[0]]

  return (
    <ScrollView>
      <WhiteSpace size="lg" />
      <List>
        <Picker
          data={[
            { value: 'VND', label: '越南盾' },
            { value: 'CNY', label: '人民币' }
          ]}
          cols={1}
          value={currency}
          onChange={vals => {
            setCurrency(vals)
          }}
        >
          <List.Item arrow="horizontal">选择币种</List.Item>
        </Picker>

        <List.Item
          arrow="horizontal"
          extra={
            params?.item ? `${bankName}(${card?.slice(-4)})` : '请选择银行卡'
          }
          onPress={() => {
            navigation.navigate('BankCard', {
              screenName: 'WithDrawal',
              mode: 'select'
            })
          }}
        >
          到账银行卡
        </List.Item>
      </List>
      <WhiteSpace size="lg" />
      <List>
        <InputItem
          editable={false}
          type="number"
          value={balance?.amount?.toFixed(3) || '--'}
          extra={'GP'}
        >
          当前余额
        </InputItem>

        <InputItem
          keyboardType="number-pad"
          type="number"
          placeholder="请输入提现金额"
          extra={'GP'}
          // value={amount.value}
          onChangeText={text => {
            setAmount(prev => ({ ...prev, value: text }))
          }}
          /*extra={
            <Caption>
              {money.toFixed(2)} {currency[0]}
            </Caption>
          }*/
        >
          提现金额
        </InputItem>
      </List>
      <WhiteSpace size="lg" />
      <List>
        <List.Item
          extra={'1GP:' + (rateMap?.['CNY'][currency[0]] || '--') + currency[0]}
        >
          当前汇率
        </List.Item>

        <List.Item
          extra={
            money.toFixed(3).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') +
            ' ' +
            currency[0]
          }
        >
          预估到账金额
        </List.Item>
      </List>

      <WhiteSpace />

      <WhiteSpace size="lg" />
      <AntdButton type="primary" onPress={handleWithDrawal}>
        确认提现
      </AntdButton>
    </ScrollView>
  )
}

const USDTScreen = ({ rateMap, balance, navigation }) => {
  const { data: userData } = useRequest(services.userController.getUsingGET2, {
    formatResult: res => res?.data
  })
  const [amount, setAmount] = React.useState({ value: 0, error: '' })
  const [to, setTo] = React.useState({ value: '', error: '' })
  const [chain, setChain] = React.useState([])

  const handleWithDrawal = async () => {
    if (Number.isNaN(Number(amount.value)) || Number(amount.value) <= 0) {
      // 异常值
      Toast.fail('请输入正确的金额')
      return false
    }
    if (chain.length === 0 || !chain) {
      // 异常值
      Toast.fail('请选择链类型')
      return false
    }
    try {
      const { secondaryPassword } = await startSecondaryPrompt({
        title: '确认地址后输入二级密码',
        message: to.value
      })
      const {
        data,
        msg,
        code
      } = await services.userController.applyWithdrawDigitalCurrencyUsingPOST(
        {},
        {
          amount: amount.value,
          secondaryPassword,
          chain: chain?.[0],
          to: to.value
        }
      )
      if (code !== 200) {
        Toast.fail(msg)
        return
      }
      Toast.success('提币申请已提交')
      navigation.goBack()
    } catch (e) {}
  }

  const money = Number.isNaN(Number(amount.value * rateMap?.['CNY']['USDT']))
    ? 0
    : amount.value * rateMap?.['CNY']['USDT']

  return (
    <ScrollView scrollEnabled={false}>
      <WhiteSpace size="lg" />
      <List>
        <Picker
          data={[
            { value: 'ETHEREUM', label: 'ERC20 - USDT' },
            { value: 'TRON', label: 'TRC20 - USDT' }
          ]}
          cols={1}
          value={chain}
          onChange={vals => {
            setChain(vals)
          }}
        >
          <List.Item arrow="horizontal">选择链类型</List.Item>
        </Picker>
        <InputItem
          placeholder="请填写地址"
          // value={amount.value}
          onChangeText={text => {
            setTo(prev => ({ ...prev, value: text }))
          }}
        >
          账户地址
        </InputItem>
      </List>
      <WhiteSpace size="lg" />
      <List>
        <InputItem
          editable={false}
          type="number"
          value={balance?.amount?.toFixed(3) || '--'}
          extra={'GP'}
        >
          当前余额
        </InputItem>

        <InputItem
          keyboardType="number-pad"
          placeholder="请输入金额"
          // value={amount.value}
          onChangeText={text => {
            setAmount(prev => ({ ...prev, value: text }))
          }}
          extra={'GP'}
        >
          提现金额
        </InputItem>
      </List>
      <WhiteSpace size="lg" />
      <List>
        <List.Item extra={'1GP:' + (rateMap?.['CNY']['USDT'] || '--') + 'USDT'}>
          当前汇率
        </List.Item>

        <List.Item
          extra={
            money.toFixed(3).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') +
            ' USDT'
          }
        >
          预估到账金额
        </List.Item>
      </List>

      <WhiteSpace />
      <WhiteSpace size="lg" />
      <AntdButton type="primary" onPress={handleWithDrawal}>
        确认提现
      </AntdButton>
    </ScrollView>
  )
}

const WithDrawal: React.FC<any> = () => {
  const { params } = useRoute()
  const navigation = useNavigation()
  const { data: rateMap } = useRequest(
    services.userController.rateWithdrawGetUsingGET,
    {
      formatResult: res => res?.data
    }
  )
  const { data: balance } = useRequest(services.assetController.getUsingGET1, {
    formatResult: res => res?.data
  })
  return (
    <Tab.Navigator>
      <Tab.Screen name="银行卡提现">
        {props => (
          <CardScreen
            rateMap={rateMap}
            balance={balance}
            params={params}
            navigation={navigation}
            {...props}
          />
        )}
      </Tab.Screen>
      <Tab.Screen name="USDT 提现">
        {props => (
          <USDTScreen
            rateMap={rateMap}
            balance={balance}
            params={params}
            navigation={navigation}
            {...props}
          />
        )}
      </Tab.Screen>
    </Tab.Navigator>
  )
}

export default WithDrawal
