// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as adminController from './adminController';
import * as assetController from './assetController';
import * as userController from './userController';
export default {
  adminController,
  assetController,
  userController,
};
