import useLoading from '@/hooks/useLoading'
import services from '@/services'
import { Toast, WhiteSpace, WingBlank } from '@ant-design/react-native'
import { useRequest } from 'ahooks'
import React from 'react'
import { View } from 'react-native'
import { Caption } from 'react-native-paper'
import QRCode from 'react-native-qrcode-svg'

export interface ReceiptProps {}

const Receipt: React.FC<ReceiptProps> = () => {
  const { data: userData, loading } = useRequest(
    services.userController.getUsingGET2,
    {
      formatResult: res => res?.data
    }
  )

  React.useEffect(() => {
    if (!userData?.id) {
      Toast.fail('信息加载失败')
    }
  }, [userData])

  useLoading(loading)

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }
  return (
    <WingBlank>
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <View
        style={{ backgroundColor: '#fff', elevation: 10, borderRadius: 10 }}
      >
        <View style={{ alignItems: 'center', paddingVertical: 24 }}>
          <Caption style={{ textAlign: 'center', fontSize: 16 }}>
            钱包扫一扫，向我付款
          </Caption>
          <View style={{ marginVertical: 64 }}>
            <QRCode value={`${userData?.id}`} size={200} />
          </View>
        </View>
      </View>
    </WingBlank>
  )
}

export default Receipt
