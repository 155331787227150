import { Toast } from '@ant-design/react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import { extend } from 'umi-request'
import { STORES } from '../constants/index'

export const getAccessToken = async () => {
  return await AsyncStorage.getItem(STORES.ACCESS_TOKEN)
}
export const setAccessToken = async (data: string): Promise<void> => {
  await AsyncStorage.setItem(STORES.ACCESS_TOKEN, data)
}
export const logout = async () => {
  await AsyncStorage.removeItem(STORES.ACCESS_TOKEN)
}

export const request = async (...args) => {
  console.log('AsyncStorage', await AsyncStorage.getItem(STORES.ACCESS_TOKEN))
  const accessToken = await getAccessToken()
  console.log('request accessToken', accessToken)
  console.log(...args)
  const requestMethod = extend({
    prefix: Constants.manifest.extra.API_HOST,
    mode: 'cors',
    credentials: 'include',
    headers: {
      access_token: accessToken
    },
    param: {
      access_token: accessToken
    },
    errorHandler: error => {
      if (error?.message?.indexOf('timeout') > -1) {
        Toast.fail(error.message)
      }
    },
    timeout: 10000
  })
  requestMethod.interceptors.response.use(async response => {
    const data = await response.clone().json()
    if (data && data.msg.includes('token已失效')) {
      Toast.fail(data.msg)
      // 清掉 token
      await logout()
      return response
    }
    return response
  })
  return requestMethod(...args)
}
