import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { HelperText, TextInput as Input } from 'react-native-paper'
import PhoneInput from 'react-native-phone-number-input'
import { theme } from '../core/theme'

type Props = React.ComponentProps<typeof Input> & { errorText?: string }

const TextInput = ({ errorText, style, ...props }: Props) => (
  <View style={[styles.container, style]}>
    <Input
      style={styles.input}
      selectionColor={theme.colors.primary}
      underlineColor="transparent"
      {...props}
    />
    <HelperText visible={!!errorText} type="error">
      {errorText}
    </HelperText>
  </View>
)
export const AreaInput: React.FC<typeof Input> = ({
  errorText,
  style,
  ...props
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <PhoneInput
      countryPickerButtonStyle={styles.pickerbutton} 
       containerStyle={styles.phonecontainer} 
       //textContainerStyle
       textInputStyle={styles.textcontainer}
       {...props} />
      <HelperText visible={!!errorText} type="error">
        {errorText}
      </HelperText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginVertical: 12
  },
  input: {
    backgroundColor: theme.colors.surface
  },
  pickerbutton: {
    width: '30%'
  },
  phonecontainer: {
    width: '100%'
  },
  textcontainer: {
    width: '100%'
  },
  
})

export const PasswordInputText: React.FC<typeof Input> = props => {
  const [showPassword, setShowPassword] = React.useState(false)
  const { secureTextEntry, right, ...restProps } = props
  return (
    <TextInput
      {...restProps}
      secureTextEntry={!showPassword}
      right={
        <Input.Icon
          onPress={() => setShowPassword(prev => !prev)}
          name={showPassword ? 'eye-off' : 'eye'}
        />
      }
    />
  )
}

export default memo(TextInput)
