import { Modal, Toast } from '@ant-design/react-native'
import Clipboard from 'expo-clipboard'

export const emailValidator = (email: string) => {
  const re = /\S+@\S+\.\S+/

  if (!email || email.length <= 0) return '账号不能为空'
  if (!re.test(email)) return 'Ooops! We need a valid email address.'

  return ''
}

export const passwordValidator = (password: string) => {
  if (!password || password.length <= 0) return '密码不能为空'

  return ''
}

export const nameValidator = (name: string) => {
  if (!name || name.length <= 0) return 'Name cannot be empty.'

  return ''
}

export const noop = () => {}

export const startConfirmSecondaryPassword = (
  userData: API.User
): Promise<{ secondaryPassword: string }> => {
  return new Promise((resolve, reject) => {
    // 输入二级密码
    Modal.prompt(
      '请输入二级密码',
      '',
      async (secondaryPassword: any) => {
        console.log(`secondaryPassword: ${secondaryPassword}`)
        resolve({ secondaryPassword })
      },
      'secure-text',
      '',
      ['6 位数二级密码']
    )
  })
}

export const startConfirm = ({
  title,
  message,
  type,
  defaultValue = '',
  placeholder = ''
}: {
  title: string
  message: string
  type: string
  placeholder?: string
  defaultValue?: string
}): Promise<any> => {
  return new Promise((resolve, reject) => {
    // 输入二级密码
    Modal.prompt(title, message, resolve, type, defaultValue, [placeholder])
  })
}

export const startSecondaryPrompt = ({
  title,
  message
}: {
  title: string
  message: string
}): Promise<{ secondaryPassword: string }> => {
  return new Promise((resolve, reject) => {
    // 输入二级密码
    Modal.prompt(
      title,
      message,
      async (secondaryPassword: any) => {
        console.log(`secondaryPassword: ${secondaryPassword}`)
        resolve({ secondaryPassword })
      },
      'secure-text',
      '',
      ['6 位数二级密码']
    )
  })
}

/**
 * 复制
 */
export const handleCopyData = (data: any) => {
  try {
    Clipboard.setString(data)
    Toast.success('复制成功')
  } catch (e) {
    Toast.fail(e.message)
  }
}

export { default as bikini } from './bikini'
