// @ts-ignore
/* eslint-disable */
import { request } from '@/network/request'

/** 获得用户信息 GET /user */
export async function getUsingGET2(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/users', {
    method: 'GET',
    headers: {},
    params: { ...params },
    ...(options || {})
  })
}

/** 提交银行卡充值申请 POST /user/apply_deposit_bank */
export async function applyDepositBankUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.ApplyDepositBankDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/apply_deposit_bank', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 提交企业申请 POST /user/apply_update */
export async function applyUpdateUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.ApplyUpdateDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/apply_update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 提交银行卡提现申请 type(phone,mail) POST /user/apply_withdraw_bank */
export async function applyWithdrawBankUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.ApplyWithdrawBankDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/apply_withdraw_bank', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 提交虚拟货币提现申请 type(phone,mail) POST /user/apply_withdraw_digital_currency */
export async function applyWithdrawDigitalCurrencyUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.ApplyWithdrawDigitalCurrencyDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>(
    '/user/apply_withdraw_digital_currency',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      params: { ...params },
      data: body,
      ...(options || {})
    }
  )
}

/** 获得用户银行卡 GET /user/bank_get */
export async function bankGetUsingGET(
  params: {
    // query
    /** userId */
    userId?: number
    /** page */
    page?: number
    /** size */
    size?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/bank_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 获得用户银行卡 GET /user/bank_sys_get */
export async function banksysGetUsingGET(
  params: {
    // query
    /** userId */
    userId?: number
    /** page */
    page?: number
    /** size */
    size?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/bank_sys_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 绑定银行卡 POST /user/bind_bank */
export async function bindBankUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.BindBankDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/bind_bank', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 绑定谷歌身份认证 POST /user/bind_google_auth */
export async function bindGoogleAuthUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.BindGoogleAuthDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/bind_google_auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 绑定二级密码 POST /user/bind_secondary_password */
export async function bindSecondaryPasswordUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.BindSecondaryPasswordDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/bind_secondary_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 验证谷歌身份 GET /user/check_google_auth */
export async function checkGoogleAuthUsingGET(
  params: {
    // query
    /** code */
    code?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/check_google_auth', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 编辑用户 POST /user/edit */
export async function editUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.EditDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 修改邮箱 GET /user/edit_mail */
export async function editMailUsingGET(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.EditMailDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListMessage_>('/user/edit_mail', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 修改密码 GET /user/edit_password */
export async function editPasswordUsingGET(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.EditPasswordDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListMessage_>('/user/edit_password', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 修改手机号 GET /user/edit_phone */
export async function editPhoneUsingGET(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.EditPhoneDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListMessage_>('/user/edit_phone', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 修改二级密码 POST /user/edit_secondary_password */
export async function editSecondaryPasswordUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.EditSecondaryPasswordDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/edit_secondary_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}
/** 修改密码 POST /user/edit_password */
export async function editPasswordUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.EditPasswordDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/edit_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 获得区块链地址 GET /user/get_addr */
export async function getAddrUsingGET(
  params: {
    // query
    /** chain */
    chain?: string
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/get_addr', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 获得商户用户 GET /user/get_company */
export async function getCompanyUsingGET(
  params: {
    // query
    /** page */
    page?: number
    /** size */
    size?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListUser_>('/user/get_company', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 获得谷歌密钥 GET /user/get_google_key */
export async function getGoogleKeyUsingGET(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/get_google_key', {
    method: 'GET',
    headers: {},
    params: { ...params },
    ...(options || {})
  })
}

/** 获得配置 GET /user/get_sys_config */
export async function getSysConfigUsingGET(
  params: {
    // query
    /** key */
    key?: string
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/get_sys_config', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 获得消息 msgType(private,public) type(letter,banner) GET /user/message_get */
export async function messageGetUsingGET1(
  params: {
    // query
    /** type */
    type?: string
    /** msgType */
    msgType?: string
    /** page */
    page?: number
    /** size */
    size?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListMessage_>('/user/message_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 登陆 POST /user/ops_login */
export async function loginUsingPOST1(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.LoginDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/ops_login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 发送短信/邮件 type{mail,international_phone,internal_phone} msgType{signup,edit_mail,edit_phone,edit_password,withdraw_digital_currency} POST /user/ops_send_sms */
export async function sendSmsUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.SendSMSDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/ops_send_sms', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 注册 POST /user/ops_signup */
export async function signupUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.SignupDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/ops_signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 找回密码 POST /user/forgot_password */
export async function forgotUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.ForgotDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/forget_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 获取费率 GET /user/rate_get */
export async function rateGetUsingGET(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/rate_get', {
    method: 'GET',
    headers: {},
    params: { ...params },
    ...(options || {})
  })
}
/** 获取费率 GET /user/rate_withdraw_get */
export async function rateWithdrawGetUsingGET(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/rate_withdraw_get', {
    method: 'GET',
    headers: {},
    params: { ...params },
    ...(options || {})
  })
}

/** 查询用户 GET /user/search */
export async function searchUsingGET(
  params: {
    // query
    /** name */
    name?: string
    /** id */
    id?: number
    /** companyId */
    companyId?: string
    /** companyName */
    companyName?: string
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseUser_>('/user/search', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 查询用户 GET /user/search_easy */
export async function searchEasyUsingGET(
  params: {
    // query
    /** param */
    param?: string
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListUser_>('/user/search_easy', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 解除绑定银行卡 POST /user/unbind_bank */
export async function unbindBankUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.UnbindBankDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/unbind_bank', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

export async function setMemoUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.SetMemoDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/user/add_info', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 获得联系人(最近转账的人) GET /user/search_list */
export async function MemUserListGetUsingGET(
  params: {
    // query
    /** page */
    page?: number
    /** size */
    size?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListUser_>('/user/search_list', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 提交数字货币充值申请 POST /user/apply_deposit_digital_currency */
export async function applyDepositDigitalUsingPOST(
  params: {
    // query
    /** userId */
    address?: string
    amount?: number
    chain?: string

    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>(
    '/user/apply_deposit_digital_currency',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      params: { ...params },
      ...(options || {})
    }
  )
}
