import TextInput from '@/components/TextInput'
import services from '@/services'
import { Toast, WhiteSpace, WingBlank } from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { View } from 'react-native'
import { Button } from 'react-native-paper'

const ChangeUserName: React.FC<any> = () => {
  const navigation = useNavigation()
  const { data: userData, loading } = useRequest(
    services.userController.getUsingGET2,
    {
      formatResult: res => res?.data
    }
  )

  const [name, setName] = React.useState({ value: '', error: '' })

  React.useEffect(() => {
    if (userData?.name) {
      setName(prev => ({ ...prev, value: userData.name }))
    }
  }, [userData?.name])

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }

  const handleChangeUserName = async () => {
    const { code, msg, data } = await services.userController.editUsingPOST(
      {},
      {
        name: name.value
      }
    )
    console.log('data', data)
    if (code !== 200) {
      Toast.fail(msg)
      return
    }
    Toast.success('修改成功')
    // 成功了
    navigation.goBack()
  }

  return (
    <View>
      <WingBlank>
        <View style={{ width: '100%' }}>
          <TextInput
            label="用户名"
            placeholder="请输入用户名"
            value={name.value}
            onChangeText={text => setName(prev => ({ ...prev, value: text }))}
          />
          {/* <SMSTextInput onChangeText={} /> */}
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <Button mode="contained" onPress={handleChangeUserName}>
            确认修改
          </Button>
        </View>
      </WingBlank>
    </View>
  )
}

export default ChangeUserName
