import services from '@/services'
import {
  Button,
  InputItem,
  List,
  Picker,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { ScrollView } from 'react-native'
import { Caption } from 'react-native-paper'

export interface BindBankCardProps {}

const BindBankCard: React.FC<BindBankCardProps> = props => {
  const navigation = useNavigation()

  const [bankName, setBankName] = React.useState({ value: [], error: '' })
  const [card, setCard] = React.useState({ value: '', error: '' })
  const [name, setName] = React.useState({ value: '', error: '' })
  const [editbank, setEditBank] = React.useState({ value: '', error: '' })
  const handleBindBankCard = async () => {
    const result = await services.userController.bindBankUsingPOST(
      {},
      {
        bankName:
          bankName?.value?.[1] == 'NULL'
            ? editbank.value
            : bankName?.value?.[1],
        country: bankName?.value?.[0],
        card: card.value,
        name: name.value
      }
    )

    console.log('result', result)

    if (result.code === 200) {
      // 成功
      Toast.success('绑定银行卡成功')
      navigation.goBack()
    } else {
      // 失败
      Toast.fail(result?.msg ?? '绑定银行卡失败')
    }
  }

  return (
    <ScrollView>
      <WhiteSpace size="lg" />
      <List>
        <InputItem
          keyboardType="number-pad"
          placeholder="请输入您的银行卡号"
          // value={amount.value}
          onChangeText={text => {
            setCard(prev => ({ ...prev, value: text }))
          }}
        >
          卡号
        </InputItem>

        <InputItem
          placeholder="请输入持卡人姓名"
          // value={amount.value}
          onChangeText={text => {
            setName(prev => ({ ...prev, value: text }))
          }}
        >
          姓名
        </InputItem>

        <Picker
          data={[
            {
              value: 'CHN',
              label: '中国',
              children: [
                { value: '中国农业银行', label: '中国农业银行' },
                { value: '中国工商银行', label: '中国工商银行' },
                { value: '中国建设银行', label: '中国建设银行' },
                { value: '中国招商银行', label: '中国招商银行' },
                { value: '中国银行', label: '中国银行' },
                { value: '中信银行', label: '中信银行' },
                { value: '兴业银行', label: '兴业银行' },
                { value: '光大银行', label: '光大银行' },
                { value: '中国邮政储蓄银行', label: '中国邮政储蓄银行' },
                { value: '上海浦东发展银行', label: '上海浦东发展银行' },
                { value: '广发银行', label: '广发银行' },
                { value: 'NULL', label: '没有您的银行请手动输入' }
              ]
            },
            {
              value: 'VNM',
              label: '越南',
              children: [
                { value: 'VIETINBANK', label: 'VIETINBANK' },
                { value: 'AGRIBANK', label: 'AGRIBANK' },
                { value: 'BIDV', label: 'BIDV' },
                { value: 'VPBANK', label: 'VPBANK' },
                { value: 'ABBANK', label: 'ABBANK' },
                { value: 'ACB', label: 'ACB' },
                { value: 'BACA', label: 'BACA' },
                { value: 'BAO VIET BANK', label: 'BAO VIET BANK' },
                { value: 'CBBank', label: 'CBBank' },
                { value: 'CIMB', label: 'CIMB' },
                { value: 'DONG A BANK', label: 'DONG A BANK' },
                { value: 'MSB', label: 'MSB' },
                { value: 'NAM A', label: 'NAM A' },
                { value: 'TPBANK', label: 'TPBANK' },
                { value: 'NULL', label: '没有您的银行请手动输入' }
              ]
            }
          ]}
          cols={2}
          value={bankName.value}
          onChange={vals => {
            setBankName(prev => ({ ...prev, value: vals }))
          }}
        >
          <List.Item arrow="horizontal">选择银行</List.Item>
        </Picker>
        {bankName?.value?.[1] == 'NULL' ? (
          <InputItem
            placeholder="如列表内无银行可填写"
            value={editbank.value}
            onChangeText={text => {
              setEditBank(prev => ({ ...prev, value: text }))
            }}
          >
            填写银行
          </InputItem>
        ) : null}
      </List>
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WingBlank>
        <Button type="primary" onPress={handleBindBankCard}>
          确认
        </Button>
        <WhiteSpace />
        <Caption style={{ textAlign: 'center' }}>
          我们承诺：银行卡信息仅在您提现时使用，请放心填写
        </Caption>
      </WingBlank>
    </ScrollView>
  )
}

export default BindBankCard
