import CompanyBadge from '@/components/CompanyBadge'
import TextInput from '@/components/TextInput'
import { IMGBB_KEY } from '@/constants'
import { startConfirmSecondaryPassword } from '@/core/utils'
import services from '@/services'
import {
  Button,
  Modal,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import axios from 'axios'
import * as ImagePicker from 'expo-image-picker'
import React from 'react'
import { ScrollView, Text, View } from 'react-native'
import { Avatar, Caption } from 'react-native-paper'

const Tab = createMaterialTopTabNavigator()

const Transfer: React.FC<any> = () => {
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const route = useRoute()

  const { data: userData } = useRequest(services.userController.getUsingGET2, {
    formatResult: res => res?.data,
    ready: isFocused,
    refreshDeps: [isFocused]
  })
  const { data: balance, run, cancel } = useRequest(
    services.assetController.getUsingGET1,
    {
      pollingInterval: 8000,
      pollingWhenHidden: false,
      manual: true,
      formatResult: res => res?.data
    }
  )
  // const { data: rateMap } = useRequest(
  //   services.userController.rateGetUsingGET,
  //   {
  //     formatResult: res => res?.data
  //   }
  // )

  const [userId, setUserId] = React.useState({ value: '', error: '' })
  const [remark, setRemark] = React.useState({ value: '', error: '' })
  const [headUrl, setHeaderUrl] = React.useState('')
  const [canedit, setCanEdit] = React.useState(true)

  const [imgUrl, setImgUrl] = React.useState('')
  const [data, setData] = React.useState({})
  const [name, setName] = React.useState('')
  const [type, setType] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [memInfo, setMemInfo] = React.useState('')
  const [transferRemark, setTransferRemark] = React.useState('')
  const [ratioTransfer, setRatioTransfer] = React.useState('')
  const [ratioExchange, setRatioExchange] = React.useState('')
  const [amount, setAmount] = React.useState({ value: 0, error: '' })

  React.useEffect(() => {
    if (route.params?.id) {
      // 返回了一个值
      setUserId(prev => ({ ...prev, value: route.params?.id }))
      setCanEdit(false)
      getAccountInfo(route.params?.id)
    }
    if (route.params?.headUrl) {
      setHeaderUrl(route.params?.headUrl)
    }
    if (route.params?.type) {
      setType(route.params?.type)
    }
    if (route.params?.name) {
      setName(route.params?.name)
    }
    if (route.params?.transferRemark) {
      setTransferRemark(route.params.transferRemark)
    }
    if (route.params?.companyName) {
      setCompanyName(route.params.companyName)
    }
    if (route.params?.ratioTransfer) {
      setRatioTransfer(route.params.ratioTransfer)
    }
    if (route.params?.ratioExchange) {
      setRatioExchange(route.params.ratioExchange)
    }
  }, [route.params])

  React.useEffect(() => {
    isFocused ? run({}) : cancel({})
  }, [isFocused])

  const prompt = Modal.prompt

  const handleAccountIdBlur = async () => {
    // 拿这里的 id 去查
    const { data } = await services.userController.searchEasyUsingGET({
      param: userId.value
    })
    const current = data || {}
    console.log('current', current)
    setData(data || '')
    setName(current?.name || '')
    setHeaderUrl(current?.headUrl || '')
    setType(current?.type || '')
    setTransferRemark(current?.transferRemark || '')
    setMemInfo(current?.memInfo || '')
    setCompanyName(current?.companyName || '')
    setRatioTransfer(current?.ratioTransfer || '')
    setRatioExchange(current?.ratioExchange || '')
  }

  const getAccountInfo = async uid => {
    // 拿这里的 id 去查
    const { data } = await services.userController.searchEasyUsingGET({
      param: uid
    })
    const current = data || {}
    console.log('current', current)
    setData(data || '')
    setName(current?.name || '')
    setHeaderUrl(current?.headUrl || '')
    setType(current?.type || '')
    setTransferRemark(current?.transferRemark || '')
    setMemInfo(current?.memInfo || '')
    setCompanyName(current?.companyName || '')
    setRatioTransfer(current?.ratioTransfer || '')
    setRatioExchange(current?.ratioExchange || '')
  }
  const handleChangeMemo = async e => {
    try {
      const { code, msg } = await services.userController.setMemoUsingPOST(
        {},
        {
          companyId: userId.value,
          info: e
        }
      )

      if (code !== 200) {
        Toast.fail(msg)
        return
      }
      setMemInfo(e || '')
      Toast.success('设置成功')
    } catch (_) {}
  }

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: false
    })

    console.log(result)

    if (!result.cancelled) {
      const { uri } = result
      const image = uri.replace(/^data:image\/[a-z]+;base64,/, '')

      // 上传到 CDN
      const formData = new FormData()
      formData.append('image', image)

      Toast.loading('上传中')

      const { status, data, error } = await axios
        .post(`https://api.imgbb.com/1/upload?key=${IMGBB_KEY}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => res?.data)

      if (status !== 200) {
        Toast.fail(error?.message)
        return
      }

      setImgUrl(data.url)
      Toast.removeAll()
    }
  }

  const handleTransfer = async () => {
    if (!data?.token) {
      Toast.fail('请确认账户是否填写正确!')
      return false
    }
    if (type == 'company' && !memInfo) {
      Toast.fail('商户转账必须设置预留信息!')
      return false
    }
    if (Number.isNaN(Number(amount.value)) || Number(amount.value) <= 0) {
      // 异常值
      Toast.fail('请输入正确的金额')
      return false
    }
    if (userId?.error || amount?.error) {
      return
    }
    if (!userData.secondaryPassword) {
      // 先去设置下二级密码
      Toast.info('未设置二级密码')
      // 跳转到 修改交易密码
      navigation.navigate('ChangeSecondary')
      return
    }
    try {
      const { secondaryPassword } = await startConfirmSecondaryPassword(
        userData
      )
      console.log('secondaryPassword111', secondaryPassword)
      const {
        code,
        msg,
        data
      } = await services.assetController.transferUsingPOST(
        {},
        {
          amount: Number(amount.value),
          secondaryPassword,
          remark: remark.value,
          toUserId: Number(userId.value)
        }
      )

      if (code !== 200) {
        Toast.fail(msg)
        return
      }
      Toast.success('转账成功')
      navigation.navigate('Home')
    } catch (_) {}
  }

  const handleExchange = async () => {
    if (type == 'company' && !memInfo) {
      Toast.fail('商户兑换必须设置预留信息!')
      return false
    }
    navigation.navigate('Exchange', {
      ...data
    })
  }

  const transnum = Number.isNaN(Number(amount.value * ratioTransfer))
    ? 0
    : amount.value * ratioTransfer

  const exchgnum = Number.isNaN(Number(amount.value / ratioExchange))
    ? 0
    : amount.value / ratioExchange

  const commonContent = (
    <View>
      <View style={{ alignItems: 'center', paddingVertical: 16 }}>
        <Avatar.Image
          style={{
            backgroundColor: 'transparent'
          }}
          size={64}
          source={
            headUrl ? { uri: headUrl } : require('@/assets/defaultAvatar.png')
          }
        />
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 8
          }}
        >
          {typeof name === 'string' && !!name && (
            <Text style={{ marginRight: 3 }}>{name}</Text>
          )}
          {type === 'company' && <CompanyBadge />}
        </View>
      </View>
      <View>
        {canedit ? (
          <TextInput
            label="转入账户"
            keyboardType="number-pad"
            placeholder="请填写会员 ID 或商户 ID"
            editable={canedit}
            onChangeText={text => setUserId({ value: text })}
            onBlur={handleAccountIdBlur}
            style={
              type === 'company' && transferRemark
                ? {
                    marginBottom: 0
                  }
                : {}
            }
            /*right={
              <Input.Icon
                color="gray"
                onPress={() =>
                  navigation.navigate('Contact', {
                    screenName: 'Transfer',
                    mode: 'select'
                  })
                }
                name="account-box"
              />
            }*/
          />
        ) : (
          <Text
            style={
              type === 'company' && transferRemark
                ? {
                    marginBottom: 0,
                    textAlign: 'center'
                  }
                : {
                    textAlign: 'center'
                  }
            }
          >
            转入ID：{userId.value}
          </Text>
        )}

        {type === 'company' && memInfo ? (
          <Caption
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginBottom: 10,
              color: '#00a1ff'
            }}
            onPress={() =>
              prompt(
                '商户预留信息',
                '填写后您的信息将被商户可见',
                [
                  { text: '取消' },
                  {
                    text: '提交',
                    onPress: value => handleChangeMemo(value)
                  }
                ],
                'default',
                memInfo
              )
            }
          >
            您的商户预留信息： 【{memInfo}】 点击修改
          </Caption>
        ) : type === 'company' ? (
          <Caption
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginBottom: 10,
              color: '#00a1ff'
            }}
            onPress={() =>
              prompt(
                '商户预留信息',
                '填写后您的信息将被商户可见',
                [
                  { text: '取消' },
                  {
                    text: '提交',
                    onPress: value => handleChangeMemo(value)
                  }
                ],
                'default',
                ''
              )
            }
          >
            您未设置商户预留信息，点击设置
          </Caption>
        ) : null}
        {type === 'company' ? (
          <Button type="ghost" onPress={handleExchange}>
            进入兑换页面
          </Button>
        ) : null}
      </View>
    </View>
  )

  // const money = rateMap?.CNY * amount.value
  return (
    <ScrollView>
      <WingBlank>
        {commonContent}
        {type === 'company' && transferRemark ? (
          <Caption
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginBottom: 16
            }}
          >
            商户提示：{transferRemark}
          </Caption>
        ) : null}

        <TextInput
          // style={{ height: 100 }}
          label="转账金额"
          placeholder="请填写金额"
          keyboardType="numeric"
          style={{
            marginBottom: 0
          }}
          onChangeText={text => {
            setAmount({ value: text })
          }}
        />
        {ratioTransfer ? (
          <Caption
            style={{
              marginTop: 0,
              marginLeft: 10,
              marginBottom: 16
            }}
          >
            余额：{balance?.amount?.toFixed(3) || '--'} GP 平台转入比例: 1:
            {ratioTransfer} 可获得代币:
            {transnum.toFixed(3)}
          </Caption>
        ) : (
          <Caption
            style={{
              marginTop: 0,
              marginLeft: 10,
              marginBottom: 16
            }}
          >
            余额：{balance?.amount?.toFixed(3) || '--'} GP
          </Caption>
        )}
        <TextInput
          // style={{ height: 100 }}
          label="备注"
          placeholder="请输入备注"
          style={{
            marginBottom: 0
          }}
          onChangeText={text => setRemark(prev => ({ ...prev, value: text }))}
        />

        <WhiteSpace size="lg" />
        <Button type="primary" onPress={handleTransfer}>
          确认转账
        </Button>
      </WingBlank>
    </ScrollView>
  )
}

export default Transfer
