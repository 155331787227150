// @ts-ignore
/* eslint-disable */
import { request } from '@/network/request'

/** 获得用户资产信息 GET /asset */
export async function getUsingGET1(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/asset/asset', {
    method: 'GET',
    headers: {},
    params: { ...params },
    ...(options || {})
  })
}

/** 获得联系人(最近转账的人) GET /asset/contact_get */
export async function contactGetUsingGET(
  params: {
    // query
    /** page */
    page?: number
    /** size */
    size?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListUser_>('/asset/contact_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 充值(仅限后台管理使用) POST /asset/deposit */
export async function depositUsingPOST(
  params: {
    // query
    /** enMsg */
    enMsg?: string
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/asset/deposit', {
    method: 'POST',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 获得用户资产日志 GET /asset/log */
export async function getLogUsingGET(
  params: {
    // query
    /** type */
    type?: string
    /** page */
    page?: number
    /** size */
    size?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/asset/log', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}
/** 获得用户账单日志 GET /asset/bill */
export async function getBillUsingGET(
  params: {
    // query
    /** type */
    type?: string
    /** page */
    page?: number
    /** size */
    size?: number
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/asset/bill', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 内部转账 POST /asset/transfer */
export async function transferUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.TransferDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/asset/transfer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 商户兑换 POST /asset/exchange_company */
export async function exchangeUsingPOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.ExchangeDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/asset/exchange_company', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

/** 商户兑换列表 POST /asset/exchange_company */
export async function ExchangeListGetUsingGET(
  params: {
    // query
    // header
    /** access_token */
    access_token?: string
  },
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseListUser_>('/asset/exchange_company_get', {
    method: 'GET',
    headers: {},
    params: {
      ...params
    },
    ...(options || {})
  })
}

export async function editExchangePOST(
  params: {
    // header
    /** access_token */
    access_token?: string
  },
  body: API.EditExchangeDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/asset/edit_exchange_company', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...params },
    data: body,
    ...(options || {})
  })
}

export async function onlinePayPOST(
  params: {
    // query
    /** enMsg */
    // header
    /** access_token */
    access_token?: string
  },
  body: API.OnlinePayDTO,
  options?: { [key: string]: any }
) {
  return request<API.GamePayResponseObject_>('/asset/online_pay', {
    method: 'POST',
    headers: {},
    params: {
      ...params
    },
    data: body,
    ...(options || {})
  })
}
