import { theme } from '@/core/theme'
import React from 'react'
import { Text, View } from 'react-native'

const CompanyBadge = () => {
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: theme.colors.primary,
        borderRadius: 6,
        paddingHorizontal: 3,
        backgroundColor: 'transparent',
        marginRight: 3
      }}
    >
      <Text
        style={{
          color: theme.colors.primary,
          fontSize: 12,
          lineHeight: 16
        }}
      >
        商户
      </Text>
    </View>
  )
}

export default CompanyBadge
