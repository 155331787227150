import CompanyBadge from '@/components/CompanyBadge'
import TextInput from '@/components/TextInput'
import { IMGBB_KEY } from '@/constants'
import { handleCopyData } from '@/core/utils'
import services from '@/services'
import {
  Button,
  Icon,
  InputItem,
  List,
  Modal,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import axios from 'axios'
import * as ImagePicker from 'expo-image-picker'
import React from 'react'
import { Image, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { Avatar, Caption } from 'react-native-paper'

const Tab = createMaterialTopTabNavigator()

const Exchange: React.FC<any> = () => {
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const route = useRoute()

  const { data: userData } = useRequest(services.userController.getUsingGET2, {
    formatResult: res => res?.data,
    ready: isFocused,
    refreshDeps: [isFocused]
  })
  const { data: balance, run, cancel } = useRequest(
    services.assetController.getUsingGET1,
    {
      pollingInterval: 8000,
      pollingWhenHidden: false,
      manual: true,
      formatResult: res => res?.data
    }
  )
  // const { data: rateMap } = useRequest(
  //   services.userController.rateGetUsingGET,
  //   {
  //     formatResult: res => res?.data
  //   }
  // )

  const [userId, setUserId] = React.useState({ value: '', error: '' })
  const [remark, setRemark] = React.useState({ value: '', error: '' })
  const [headUrl, setHeaderUrl] = React.useState('')
  const [canedit, setCanEdit] = React.useState(true)

  const [imgUrl, setImgUrl] = React.useState('')
  const [data, setData] = React.useState({})
  const [name, setName] = React.useState('')
  const [type, setType] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [memInfo, setMemInfo] = React.useState('')
  const [transferRemark, setTransferRemark] = React.useState('')
  const [ratioTransfer, setRatioTransfer] = React.useState('')
  const [ratioExchange, setRatioExchange] = React.useState('')
  const [amount, setAmount] = React.useState({ value: 0, error: '' })

  React.useEffect(() => {
    if (route.params?.id) {
      // 返回了一个值
      setUserId(prev => ({ ...prev, value: route.params?.id }))
      setCanEdit(false)
      getAccountInfo(route.params?.id)
    }
    if (route.params?.headUrl) {
      setHeaderUrl(route.params?.headUrl)
    }
    if (route.params?.type) {
      setType(route.params?.type)
    }
    if (route.params?.name) {
      setName(route.params?.name)
    }
    if (route.params?.transferRemark) {
      setTransferRemark(route.params.transferRemark)
    }
    if (route.params?.companyName) {
      setCompanyName(route.params.companyName)
    }
    if (route.params?.ratioTransfer) {
      setRatioTransfer(route.params.ratioTransfer)
    }
    if (route.params?.ratioExchange) {
      setRatioExchange(route.params.ratioExchange)
    }
  }, [route.params])

  React.useEffect(() => {
    isFocused ? run({}) : cancel({})
  }, [isFocused])

  const prompt = Modal.prompt

  const handleAccountIdBlur = async () => {
    // 拿这里的 id 去查
    const { data } = await services.userController.searchEasyUsingGET({
      param: userId.value
    })
    const current = data || {}
    console.log('current', current)

    setData(data || '')
    setName(current?.name || '')
    setHeaderUrl(current?.headUrl || '')
    setType(current?.type || '')
    setTransferRemark(current?.transferRemark || '')
    setMemInfo(current?.memInfo || '')
    setCompanyName(current?.companyName || '')
    setRatioTransfer(current?.ratioTransfer || '')
    setRatioExchange(current?.ratioExchange || '')
  }

  const getAccountInfo = async uid => {
    // 拿这里的 id 去查
    const { data } = await services.userController.searchEasyUsingGET({
      param: uid
    })
    const current = data || {}

    setData(data || '')
    console.log('current', current)
    setName(current?.name || '')
    setHeaderUrl(current?.headUrl || '')
    setType(current?.type || '')
    setTransferRemark(current?.transferRemark || '')
    setMemInfo(current?.memInfo || '')
    setCompanyName(current?.companyName || '')
    setRatioTransfer(current?.ratioTransfer || '')
    setRatioExchange(current?.ratioExchange || '')
  }
  const handleChangeMemo = async e => {
    try {
      const { code, msg } = await services.userController.setMemoUsingPOST(
        {},
        {
          companyId: userId.value,
          info: e
        }
      )

      if (code !== 200) {
        Toast.fail(msg)
        return
      }
      setMemInfo(e || '')
      Toast.success('设置成功')
    } catch (_) {}
  }

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true
    })

    console.log(result)

    if (!result.cancelled) {
      const { uri, base64 } = result
      const image = base64
        ? base64.replace(/^data:image\/[a-z]+;base64,/, '')
        : uri.replace(/^data:image\/[a-z]+;base64,/, '')

      // 上传到 CDN
      const formData = new FormData()
      formData.append('image', image)

      Toast.loading('上传中')

      const { status, data, error } = await axios
        .post(`https://api.imgbb.com/1/upload?key=${IMGBB_KEY}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => res?.data)

      if (status !== 200) {
        Toast.fail(error?.message)
        return
      }

      setImgUrl(data.url)
      Toast.removeAll()
    }
  }

  const handleTransfer = async () => {
    if (type == 'company' && !memInfo) {
      Toast.fail('商户转账必须设置预留信息!')
      return false
    }
    navigation.navigate('Transfer', {
      ...data
    })
  }

  const handleExchange = async () => {
    if (type == 'company' && !memInfo) {
      Toast.fail('商户兑换必须设置预留信息!')
      return false
    }
    if (Number.isNaN(Number(amount.value)) || Number(amount.value) <= 0) {
      // 异常值
      Toast.fail('请输入正确的金额')
      return false
    }
    if (userId?.error || amount?.error) {
      return
    }
    try {
      const {
        code,
        msg,
        data
      } = await services.assetController.exchangeUsingPOST(
        {},
        {
          amount: Number(amount.value),
          remark: remark.value,
          toUserId: Number(userId.value),
          imgUrl
        }
      )

      if (code !== 200) {
        Toast.fail(msg)
        return
      }
      Toast.success('兑换请求已提交')
      navigation.navigate('Home')
    } catch (_) {}
  }

  const transnum = Number.isNaN(Number(amount.value * ratioTransfer))
    ? 0
    : amount.value * ratioTransfer

  const exchgnum = Number.isNaN(Number(amount.value * ratioExchange))
    ? 0
    : amount.value * ratioExchange

  const commonContent = (
    <View>
      <View style={{ alignItems: 'center', paddingVertical: 16 }}>
        <Avatar.Image
          style={{
            backgroundColor: 'transparent'
          }}
          size={64}
          source={
            headUrl ? { uri: headUrl } : require('@/assets/defaultAvatar.png')
          }
        />
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 8
          }}
        >
          {typeof name === 'string' && !!name && (
            <Text style={{ marginRight: 3 }}>{name}</Text>
          )}
          {type === 'company' && <CompanyBadge />}
        </View>
      </View>
      <View>
        {canedit ? (
          <TextInput
            label="转入账户"
            keyboardType="number-pad"
            placeholder="请填写会员 ID 或商户 ID"
            editable={canedit}
            onChangeText={text => setUserId({ value: text })}
            onBlur={handleAccountIdBlur}
            style={
              type === 'company' && transferRemark
                ? {
                    marginBottom: 0
                  }
                : {}
            }
            /*right={
              <Input.Icon
                color="gray"
                onPress={() =>
                  navigation.navigate('Contact', {
                    screenName: 'Transfer',
                    mode: 'select'
                  })
                }
                name="account-box"
              />
            }*/
          />
        ) : (
          <Text
            style={
              type === 'company' && transferRemark
                ? {
                    marginBottom: 0,
                    textAlign: 'center'
                  }
                : {
                    textAlign: 'center'
                  }
            }
          >
            兑换商户ID：{userId.value}
          </Text>
        )}

        {type === 'company' && memInfo ? (
          <Caption
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginBottom: 10,
              color: '#00a1ff'
            }}
            onPress={() =>
              prompt(
                '商户预留信息',
                '填写后您的信息将被商户可见',
                [
                  { text: '取消' },
                  {
                    text: '提交',
                    onPress: value => handleChangeMemo(value)
                  }
                ],
                'default',
                memInfo
              )
            }
          >
            您的商户预留信息： 【{memInfo}】 点击修改
          </Caption>
        ) : type === 'company' ? (
          <Caption
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginBottom: 10,
              color: '#00a1ff'
            }}
            onPress={() =>
              prompt(
                '商户预留信息',
                '填写后您的信息将被商户可见',
                [
                  { text: '取消' },
                  {
                    text: '提交',
                    onPress: value => handleChangeMemo(value)
                  }
                ],
                'default',
                ''
              )
            }
          >
            您未设置商户预留信息，点击设置
          </Caption>
        ) : null}
        {type === 'company' ? (
          <Button type="ghost" onPress={handleTransfer}>
            进入转账页面
          </Button>
        ) : null}
      </View>
    </View>
  )

  // const money = rateMap?.CNY * amount.value
  return (
    <ScrollView>
      <WingBlank>
        {commonContent}
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <View
            style={{
              paddingVertical: 10,
              paddingHorizontal: 16,
              width: '100%'
            }}
          >
            <WhiteSpace />
            <Caption style={{}}>
              请在设置商户预留信息为您的游戏平台ID后，复制下方ID在游戏内转账，系统在2小时内自动为您处理。
            </Caption>
          </View>
          <WingBlank style={{ alignItems: 'center' }}>
            <InputItem
              value={companyName}
              editable={false}
              extra={
                <Icon
                  onPress={() => handleCopyData(companyName)}
                  color="#000"
                  name="copy"
                />
              }
            >
              商户账号
            </InputItem>

            <WhiteSpace size="lg" />
          </WingBlank>
        </View>
        <View>
          <List>
            <InputItem
              type="number"
              placeholder="请填写金额"
              onChangeText={text => {
                setAmount({ value: text })
              }}
            >
              代币数量
            </InputItem>
            <Caption style={{ marginTop: 0, marginLeft: 15 }}>
              平台兑换比例: {ratioExchange}:1 可获得GP: {exchgnum.toFixed(3)}
            </Caption>

            <InputItem
              type="text"
              value={remark.value}
              placeholder="请输入备注"
              onChangeText={text =>
                setRemark(prev => ({ ...prev, value: text }))
              }
            >
              备注
            </InputItem>

            <List.Item>
              上传凭证
              <WhiteSpace />
              {imgUrl ? (
                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 100,
                    height: 100,
                    borderColor: '#ccc',
                    borderStyle: 'dashed',
                    borderWidth: 1
                  }}
                  onPress={pickImage}
                >
                  <Image
                    source={{ uri: imgUrl }}
                    style={{ width: 100, height: 100 }}
                  />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 100,
                    height: 100,
                    borderColor: '#ccc',
                    borderStyle: 'dashed',
                    borderWidth: 1
                  }}
                  onPress={pickImage}
                >
                  <Icon name="plus" />
                  <Text style={{ marginTop: 8 }}>上传</Text>
                </TouchableOpacity>
              )}
            </List.Item>
          </List>
          <WhiteSpace size="lg" />
          <Button mode="contained" onPress={handleExchange}>
            确认兑换
          </Button>
        </View>
      </WingBlank>
    </ScrollView>
  )
}

export default Exchange
