import { WingBlank } from '@ant-design/react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'
import HTML from 'react-native-render-html'
import { View } from 'react-native'
import { Caption, Paragraph, Title } from 'react-native-paper'

const MessageDetail = () => {
  const navigation = useNavigation()
  const { params } = useRoute()

  console.log('params', params)
  const { title, date, description } = params

  React.useEffect(() => {
    if (title) {
      // set title
      navigation.setOptions({ title })
    }
  }, [params])

  return (
    <View>
      <WingBlank>
        <Title>{title}</Title>
        <Caption>{date}</Caption>
        <HTML
          html={description}
          
        />

      </WingBlank>
    </View>
  )
}

export default MessageDetail
