import DataTable from '@/components/DataTable'
import useLoading from '@/hooks/useLoading'
import services from '@/services'
import { List, Modal, Toast, WingBlank } from '@ant-design/react-native'
import { useRequest } from 'ahooks'
import React from 'react'
import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

export interface ApplysProps {}

const Applys: React.FC<ApplysProps> = props => {
  const { data, loading } = useRequest(
    () => services.assetController.getBillUsingGET({ page: 0, size: 1000 }),
    {
      formatResult: res => res?.data
    }
  )

  const [visible, setVisible] = React.useState(false)
  const [billdata, setBillData] = React.useState({})
  const statusdict = {
    pass: '已通过',
    reject: '已拒绝',
    submit: '待审核'
  }

  const datadict = {
    withdraw_bank: '银行卡提现',
    deposit_bank: '银行卡充值',
    withdraw_digital_currency: 'USDT提现',
    deposit_digital_currency: 'USDT充值'
  }

  const ShowBill = async BillData => {
    setVisible(true)
    setBillData(BillData)
  }

  useLoading(loading)

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }

  console.log('data', data)

  return (
    <ScrollView>
      <WingBlank>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>用户ID</DataTable.Title>
            <DataTable.Title>类型</DataTable.Title>
            <DataTable.Title>金额</DataTable.Title>
            <DataTable.Title>操作时间</DataTable.Title>
          </DataTable.Header>
          {data?.map(item => {
            return (
              <DataTable.Row
                key={item.id}
                onPress={() => ShowBill(item)}
                pointerEvents="none"
              >
                <DataTable.Cell>{item.userId}</DataTable.Cell>
                <DataTable.Cell>{datadict[item.type]}</DataTable.Cell>
                <DataTable.Cell>
                  {item.type == 'withdraw_bank' ||
                  item.type == 'withdraw_digital_currency'
                    ? '-'
                    : ''}
                  {item.amount ? item.amount.toFixed(3) : 0}
                </DataTable.Cell>
                <DataTable.Cell
                  textStyle={{
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {item.createdAt}
                </DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable>
      </WingBlank>

      <Modal
        title="账单信息"
        transparent
        onClose={() => setVisible(false)}
        maskClosable
        visible={visible}
        closable
      >
        <View style={{ paddingVertical: 20 }}>
          <List>
            <List.Item extra={datadict[billdata.type]}>账单类型</List.Item>
            <List.Item extra={statusdict[billdata.status]}>当前状态</List.Item>
            <List.Item
              extra={
                billdata.type == 'withdraw_bank' ||
                billdata.type == 'withdraw_digital_currency'
                  ? '-' +
                    String(billdata.amount ? billdata.amount.toFixed(3) : '0')
                  : '+' +
                    String(billdata.amount ? billdata.amount.toFixed(3) : '0')
              }
            >
              金额变动
            </List.Item>
            <List.Item extra={billdata.currency}>币种类型</List.Item>
            <List.Item extra={String(billdata.exchangeRate)}>
              操作汇率
            </List.Item>

            {billdata.type == 'withdraw_digital_currency' ||
            billdata.type == 'deposit_digital_currency' ? (
              <>
                <List.Item extra={billdata.chain}>链类型</List.Item>
                <List.Item wrap extra={String(billdata.to)} multipleLine>
                  地址/TxID
                </List.Item>
              </>
            ) : (
              <>
                <List.Item extra={String(billdata.name)}>操作姓名</List.Item>
                <List.Item wrap extra={String(billdata.to)} multipleLine>
                  对方信息
                </List.Item>
              </>
            )}
            <List.Item extra={billdata.createdAt} wrap multipleLine>
              操作时间
            </List.Item>
          </List>
        </View>
      </Modal>
    </ScrollView>
  )
}

export default Applys
