import useLoading from '@/hooks/useLoading'
import services from '@/services'
import { useHeaderHeight } from '@react-navigation/stack'
import { useRequest } from 'ahooks'
import React from 'react'
import { Dimensions, Platform, View } from 'react-native'
import { WebView } from 'react-native-webview'

const { height } = Dimensions.get('window')

export interface CustomerServiceProps {}

const CustomerService: React.FC<CustomerServiceProps> = props => {
  const headerHeight = useHeaderHeight()

  const { data, loading } = useRequest(
    () =>
      services.userController.getSysConfigUsingGET({
        key: 'robot'
      }),
    {
      formatResult: res => res?.data
    }
  )
  console.log('data', data)
  useLoading(loading)

  if (Platform.OS === 'web') {
    console.log('headerHeight', headerHeight)
    return (
      <View style={{ height: height - headerHeight }}>
        <iframe
          style={{ height: '100%' }}
          src={data}
          border={0}
          frameBorder={0}
        />
      </View>
    )
  }

  const iosZoomFix =
    "const meta = document.createElement('meta'); " +
    "meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=1'); " +
    "meta.setAttribute('name', 'viewport'); " +
    "document.getElementsByTagName('head')[0].appendChild(meta); " +
    'true; '
  return (
    <WebView
      source={{ uri: data }}
      startInLoadingState
      automaticallyAdjustContentInsets
      javaScriptEnabledAndroid
      injectedJavaScript={iosZoomFix}
    />
  )
}

export default CustomerService
