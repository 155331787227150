import { AuthContext } from '@/core/context'
import { Button, List, WhiteSpace } from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { ScrollView, View } from 'react-native'
const Item = List.Item

const Setting = () => {
  const navigation = useNavigation()
  const { signOut } = React.useContext(AuthContext)

  return (
    <View>
      <WhiteSpace size="lg" />
      <ScrollView
        style={{ backgroundColor: '#f5f5f9' }}
        automaticallyAdjustContentInsets={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <List>
          <Item
            extra={null}
            arrow="horizontal"
            onPress={() => {
              navigation.navigate('Profile')
            }}
          >
            用户资料设置
          </Item>
          <Item
            extra={null}
            arrow="horizontal"
            onPress={() => {
              navigation.navigate('ChangePassword')
            }}
          >
            修改密码
          </Item>
          <Item
            extra={null}
            arrow="horizontal"
            onPress={() => {
              navigation.navigate('ChangeSecondary')
            }}
          >
            修改二级密码
          </Item>
        </List>
      </ScrollView>
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />

      <Button onPress={signOut}>退出登录</Button>
    </View>
  )
}

export default Setting
