import DataTable from '@/components/DataTable'
import useLoading from '@/hooks/useLoading'
import services from '@/services'
import { List, Modal, Toast, WingBlank } from '@ant-design/react-native'
import { useRequest } from 'ahooks'
import React from 'react'
import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

export interface BillProps {}

const Bill: React.FC<BillProps> = props => {
  const { data, loading } = useRequest(
    () => services.assetController.getLogUsingGET({ page: 0, size: 1000 }),
    {
      formatResult: res => res?.data
    }
  )

  const [visible, setVisible] = React.useState(false)
  const [billdata, setBillData] = React.useState({})
  const datadict = {
    exchange: '商户兑换',
    transfer: '转账',
    withdraw_bank: '银行卡提现',
    deposit_bank: '银行卡充值',
    withdraw_digital_currency: 'USDT提现',
    deposit_digital_currency: 'USDT充值',
    backend_op: '人工操作'
  }

  const ShowBill = async BillData => {
    setVisible(true)
    setBillData(BillData)
  }

  useLoading(loading)

  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }

  console.log('data', data)

  return (
    <ScrollView>
      <WingBlank>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>商户</DataTable.Title>
            <DataTable.Title>类型</DataTable.Title>
            <DataTable.Title>金额</DataTable.Title>
            <DataTable.Title>开始时间</DataTable.Title>
          </DataTable.Header>
          {data?.map(item => {
            return (
              <>
                {item.type == 'transfer' || item.type == 'exchange' ? (
                  <DataTable.Row
                    key={item.id}
                    onPress={() => ShowBill(item)}
                    pointerEvents="none"
                  >
                    <DataTable.Cell>{item.userId}</DataTable.Cell>
                    <DataTable.Cell>{datadict[item.type]}</DataTable.Cell>
                    <DataTable.Cell>
                      {item.type == 'withdraw_bank' ||
                      item.type == 'withdraw_digital_currency'
                        ? '-'
                        : ''}
                      {item.changAmount ? item.changAmount.toFixed(3) : 0}
                    </DataTable.Cell>
                    <DataTable.Cell
                      textStyle={{
                        whiteSpace: 'pre-wrap'
                      }}
                    >
                      {item.createdAt}
                    </DataTable.Cell>
                  </DataTable.Row>
                ) : null}
              </>
            )
          })}
        </DataTable>
      </WingBlank>

      <Modal
        title="账单信息"
        transparent
        onClose={() => setVisible(false)}
        maskClosable
        visible={visible}
        closable
      >
        <View style={{ paddingVertical: 20 }}>
          <List>
            <List.Item extra={datadict[billdata.type]}>账单类型</List.Item>
            <List.Item
              extra={
                billdata.type == 'withdraw_bank' ||
                billdata.type == 'withdraw_digital_currency'
                  ? '-' +
                    String(
                      billdata.changAmount ? billdata.changAmount.toFixed(3) : 0
                    )
                  : String(
                      billdata.changAmount
                        ? billdata.changAmount.toFixed(3)
                        : '0'
                    )
              }
            >
              金额变动
            </List.Item>
            <List.Item
              extra={String(
                billdata.afterAmount ? billdata.afterAmount.toFixed(3) : '0'
              )}
            >
              变动前余额
            </List.Item>
            <List.Item
              extra={String(
                billdata.beforeAmount ? billdata.beforeAmount.toFixed(3) : '0'
              )}
            >
              变动后余额
            </List.Item>
            {billdata.assetId ? (
              <List.Item extra={billdata.assetId}>操作对方ID</List.Item>
            ) : null}
            <List.Item extra={billdata.createdAt} wrap multipleLine>
              操作时间
            </List.Item>
          </List>
        </View>
      </Modal>
    </ScrollView>
  )
}

export default Bill
