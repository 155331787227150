import React, { memo } from 'react'
import {
  ImageBackground,
  KeyboardAvoidingView,
  StyleProp,
  StyleSheet,
  ViewStyle
} from 'react-native'

type Props = {
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
}

const Background = ({ children, style, containerStyle }: Props) => (
  <ImageBackground
    source={require('@/assets/background_dot.png')}
    resizeMode="repeat"
    style={[styles.background, StyleSheet.flatten(style)]}
  >
    <KeyboardAvoidingView
      style={[styles.container, StyleSheet.flatten(containerStyle)]}
      behavior="padding"
    >
      {children}
    </KeyboardAvoidingView>
  </ImageBackground>
)

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%'
  },
  container: {
    flex: 1,
    padding: 20,
    width: '100%',
    maxWidth: 680,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default memo(Background)
