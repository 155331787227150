import Marquee from '@/components/Marquee'
import { AppContext, AuthContext } from '@/core/context'
import { theme } from '@/core/theme'
import services from '@/services'
import {
  Badge,
  Icon,
  List,
  Toast,
  WhiteSpace,
  WingBlank
} from '@ant-design/react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import axios from 'axios'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isValid from 'date-fns/isValid'
import zhCN from 'date-fns/locale/zh-CN'
import * as ImagePicker from 'expo-image-picker'
import React from 'react'
import {
  Dimensions,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import {
  Appbar,
  Avatar,
  Caption,
  Card,
  Headline,
  Text as PaperText
} from 'react-native-paper'

const { width } = Dimensions.get('window')

const EntryItem = props => {
  const { source, title, onPress = () => {} } = props
  return (
    <TouchableOpacity style={styles.quickEntryItem} onPress={onPress}>
      <Image source={source} style={styles.quickEntryItemIcon} />
      <Text style={styles.quickEntryItemText}>{title}</Text>
    </TouchableOpacity>
  )
}

const MessageItem: React.FC = props => {
  const { title, date, height } = props
  return (
    <View style={{ height }}>
      <Text>
        ● <Text style={{ fontSize: 13 }}>{title}</Text>
        <WingBlank>
          <Text
            style={{
              color: '#999',
              fontSize: 12
            }}
          >
            {date}
          </Text>
        </WingBlank>
      </Text>
    </View>
  )
}

const SectionTitle = ({ title, right = null }) => {
  return (
    <View
      style={{
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Text
        style={{
          borderLeftColor: theme.colors.primary,
          borderLeftWidth: 2,
          paddingLeft: 6,
          marginTop: 8,
          marginBottom: 16
        }}
      >
        {title}
      </Text>
      {right && <View>{right}</View>}
    </View>
  )
}

const ShopItem = ({ title, onPress = () => {}, source }) => {
  return (
    <View style={{ paddingHorizontal: 8, width: 100 }}>
      <Card
        onPress={onPress}
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 16,
          paddingHorizontal: 6,
          width: 90
        }}
      >
        <View style={{ alignItems: 'center' }}>
          <Avatar.Image
            style={{
              backgroundColor: 'transparent',
              marginBottom: 3
            }}
            size={48}
            source={source ?? require('@/assets/defaultAvatar.png')}
          />
        </View>
        <Text
          ellipsizeMode="clip"
          numberOfLines={2}
          style={{ fontSize: 14, textAlign: 'center' }}
        >
          {title}
        </Text>
      </Card>
    </View>
  )
}

const GridItem = ({ title, desc, source, style, onPress }: any) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          borderTopColor: '#ccc',
          padding: 16,
          borderTopWidth: 1,
          justifyContent: 'space-around'
        },
        style
      ]}
    >
      <View>
        <Text>{title}</Text>
        <Caption>{desc}</Caption>
      </View>
      <Image
        source={source}
        style={{ width: 30, height: 30 }}
        resizeMode="contain"
      />
    </TouchableOpacity>
  )
}

const Home: React.FC = () => {
  const { userData } = React.useContext(AppContext)
  const { signOut } = React.useContext(AuthContext)

  const isFocused = useIsFocused()
  const navigation = useNavigation()

  const { data: companyList = [] } = useRequest(
    () => services.userController.getCompanyUsingGET({ size: 1000, page: 0 }),
    {
      formatResult: res => res?.data
    }
  )

  const [messageStore, setMessageStore] = React.useState()

  const { data: messages } = useRequest(
    () =>
      services.userController.messageGetUsingGET1({
        // type: 'banner',
        msgType: 'public',
        page: 0,
        size: 100
      }),
    {
      formatResult: res => res?.data
    }
  )

  // 获取下 private 消息的数量
  const { data: privateMessages, run, cancel } = useRequest(
    () =>
      services.userController.messageGetUsingGET1({
        // type: 'banner',
        msgType: 'private',
        page: 0,
        size: 100
      }),
    {
      pollingWhenHidden: false,
      pollingInterval: 8000,

      onSuccess: async res => {
        if (res && res?.code !== 200 && res?.msg.includes('token已失效')) {
          // 跳转到登录页
          signOut()
          return
        }
        const data = res?.data?.length
        // first entry
        if ((await AsyncStorage.getItem('privateMessages')) === null) {
          try {
            await AsyncStorage.setItem('privateMessages', JSON.stringify(data))
            // refresh
            setMessageStore(
              (await AsyncStorage.getItem('privateMessages')) as any
            )
          } catch (e) {
            console.error('[privateMessage Error]', e)
          }
        }
      },
      manual: true,
      formatResult: res => res
    }
  )

  React.useEffect(() => {
    isFocused ? run() : cancel()
  }, [isFocused])

  const handleWebScan = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true
    })

    if (!result.cancelled) {
      const { uri, base64 } = result
      const image = base64 || uri

      Toast.loading('识别中', 1)

      const fileBlob = await axios
        .get(uri, { responseType: 'blob' })
        .then(res => res?.data)

      const formData = new FormData()
      formData.append('file', fileBlob)
      formData.append('MAX_FILE_SIZE', '1048576')

      const [decodeResult] = await axios
        .post('http://api.qrserver.com/v1/read-qr-code/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => res?.data)
        .catch(e => {
          Toast.fail('帐户识别失败')
          return Promise.reject(e)
        })

      const code = decodeResult?.symbol?.[0]?.data
      console.log('qrcode', code)

      // 拿这里的 id 去查
      const { data } = await services.userController.searchEasyUsingGET({
        param: code
      })
      console.log(data)
      if (data?.token) {
        navigation.navigate('Transfer', {
          ...data
        })
      } else {
        Toast.fail(`无法识别该账户: ${code}`)
      }
    }
  }

  console.log('privateMessages', privateMessages)
  console.log('messageStore', messageStore)

  return (
    <ScrollView>
      <Appbar.Header>
        <Appbar.Action
          icon={() => <Icon color="#fff" name="customer-service" />}
          onPress={() => {
            navigation.navigate('CustomerService')
          }}
        />
        <Appbar.Content title={null} />
        <View>
          {Number(privateMessages) > Number(messageStore) ? (
            <Badge style={{ position: 'absolute', top: 12, right: 12 }} dot />
          ) : null}
          <Appbar.Action
            onPress={() => navigation.navigate('Message')}
            icon={() => <Icon color="#fff" name="mail" />}
            color="#fff"
          />
        </View>
      </Appbar.Header>
      <View key="quickEntry" style={styles.quickEntry}>
        <EntryItem
          title="扫一扫"
          source={require('@/assets/icons/scan.png')}
          onPress={() => {
            Platform.OS === 'web'
              ? handleWebScan()
              : navigation.navigate('Scan')
          }}
        />
        <EntryItem
          title="转账"
          source={require('@/assets/icons/card.png')}
          onPress={() => {
            navigation.navigate('Transfer')
          }}
        />
        <EntryItem
          title="收款"
          source={require('@/assets/icons/collect.png')}
          onPress={() => {
            navigation.navigate('Receipt')
          }}
        />
      </View>

      {messages?.length > 0 ? (
        <List>
          <List.Item
            thumb={
              <Icon name="sound" color="#000" style={{ marginRight: 10 }} />
            }
            arrow="horizontal"
            onPress={() => {
              // 导航到公共消息
              navigation.navigate('Message', {
                // type: 'letter',
                msgType: 'public'
              })
            }}
            wrap
          >
            <View style={{ paddingTop: 8, paddingBottom: 8 }}>
              <Marquee
                data={messages}
                duration={1000}
                delay={2000}
                toValue={20}
                renderItem={(item, idx) => (
                  <MessageItem
                    key={`${item?.id}_${idx}`}
                    title={item?.title}
                    height={20}
                    date={
                      isValid(new Date(item?.updatedAt))
                        ? formatDistanceToNow(new Date(item?.updatedAt), {
                            locale: zhCN,
                            addSuffix: true
                          })
                        : ''
                    }
                  />
                )}
              />
            </View>
          </List.Item>
        </List>
      ) : null}
      <WhiteSpace size="lg" />
      <View style={styles.content}>
        <Headline>你好，{userData.name}</Headline>
        <PaperText>GamePay，全新的支付生活</PaperText>
      </View>
      <WhiteSpace size="lg" />

      <WingBlank>
        <SectionTitle
          title="一键兑换"
          right={
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('CompanyList', {
                  mode: 'select',
                  screenName: 'Transfer'
                })
              }}
            >
              <Text>更多商户</Text>
            </TouchableOpacity>
          }
        />
        <ScrollView horizontal>
          {companyList?.map?.(company => (
            <ShopItem
              key={company.id}
              title={company.name}
              source={{ uri: company.headUrl }}
              onPress={() => {
                navigation.navigate('Transfer', {
                  ...company
                })
              }}
            />
          ))}
        </ScrollView>
      </WingBlank>

      <WhiteSpace size="lg" />

      <View style={{ backgroundColor: 'white', marginVertical: 16 }}>
        <WingBlank>
          <SectionTitle title="一键充提" />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <GridItem
              title="充值"
              desc="充值到 GP 钱包"
              style={{ borderRightColor: '#ccc', borderRightWidth: 1 }}
              source={require('@/assets/reCharge.png')}
              onPress={() => {
                navigation.navigate('ReChange')
              }}
            />
            <GridItem
              title="提现"
              desc="提现到个人账户"
              source={require('@/assets/withDrawal.png')}
              onPress={() => {
                navigation.navigate('WithDrawal')
              }}
            />
          </View>
        </WingBlank>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  wrapper: {},
  content: {
    paddingHorizontal: 16
  },
  slide: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  image: {
    width,
    flex: 1,
    backgroundColor: 'transparent'
  },

  slide1: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#9DD6EB'
  },

  slide2: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#97CAE5'
  },

  slide3: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#92BBD9'
  },
  text: {
    color: '#fff',
    fontSize: 30,
    fontWeight: 'bold'
  },
  loadingView: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,.5)'
  },

  loadingImage: {
    width: 60,
    height: 60
  },
  quickEntry: {
    flexDirection: 'row',
    backgroundColor: theme.colors.primary,
    height: 100,
    paddingTop: 0
  },
  quickEntryItem: {
    padding: 10,
    flex: 1,
    alignItems: 'center'
  },
  quickEntryItemIcon: {
    width: 40,
    height: 40
  },
  quickEntryItemText: {
    marginTop: 10,
    color: '#fff',
    fontSize: 15
  }
})

export default Home
