import { handleCopyData } from '@/core/utils'
import useLoading from '@/hooks/useLoading'
import services from '@/services'
import { Icon, SearchBar, Toast } from '@ant-design/react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useRequest } from 'ahooks'
import React from 'react'
import { ScrollView } from 'react-native'
import { DataTable } from 'react-native-paper'

const MemUserList = () => {
  const navigation = useNavigation()
  const { params } = useRoute()

  const { data, loading } = useRequest(
    () =>
      services.userController.MemUserListGetUsingGET({ page: 0, size: 1000 }),
    {
      formatResult: res => res?.data
    }
  )

  const [search, setSearch] = React.useState('')

  useLoading(loading)
  let loadingIns: number

  if (loading) {
    loadingIns = Toast.loading('加载中')
    return null
  } else {
    // @ts-ignore
    if (loadingIns) {
      Toast.remove(loadingIns)
    } else {
      Toast.removeAll()
    }
  }
  const filterData = data?.filter(item => {
    if (search) {
      return [item.id, item.phone, item.type === 'normal' ? '会员' : '商户']
        .join(',')
        .includes(search)
    }
    return true
  })
  const handleMemUserSelect = item => {
    navigation.navigate('Transfer', {
      ...item
    })
  }

  console.log('Mem params', params, params?.mode === 'select')

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: '#f5f5f9' }}
      automaticallyAdjustContentInsets={false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <SearchBar placeholder="搜索" onChange={text => setSearch(text)} />
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>账户 ID</DataTable.Title>
          <DataTable.Title>手机号码</DataTable.Title>
          <DataTable.Title>预留信息</DataTable.Title>
        </DataTable.Header>
        {filterData?.map(item => {
          return (
            <DataTable.Row
              key={`${item.id}`}
              // https://github.com/callstack/react-native-paper/pull/2637
              onPress={() =>
                handleCopyData(
                  'ID:' +
                    String(item.id) +
                    ' Phone:' +
                    item.phone +
                    ' MemInfo:' +
                    item.memInfo
                )
              }
              pointerEvents="none"
            >
              <DataTable.Cell>
                <Icon name="copy" size={15} /> {item.id}
              </DataTable.Cell>
              <DataTable.Cell>{item.phone}</DataTable.Cell>
              <DataTable.Cell>{item.memInfo}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>
    </ScrollView>
  )
}

export default MemUserList
