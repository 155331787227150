import Background from '@/components/Background'
import Button from '@/components/Button'
import SMSTextInput from '@/components/SMSTextInput'
import { AreaInput, PasswordInputText } from '@/components/TextInput'
import services from '@/services'
import { Toast } from '@ant-design/react-native'
import { useNavigation } from '@react-navigation/native'
import React from 'react'

const ForgotPassword: React.FC = () => {
  const [area, setArea] = React.useState({ value :'+86', error : '' })
  const [name, setName] = React.useState('')
  const [code, setCode] = React.useState('')
  const [statePassword, setPassword] = React.useState<{
    password: string
    confirmPassword: string
  }>({
    password: '',
    confirmPassword: ''
  })
  const navigation = useNavigation()

  const handleSmsCodeValid = async () => {
    if (!code) {
      Toast.fail('请输入手机验证码')
    }
    // TODO: 校验验证码是否正确
    const result = await services.userController.forgotUsingPOST(
      {},
      {
        phone: name.value,
        code,
        password: statePassword.confirmPassword
      }
    )
    if (result.code !== 200) {
      Toast.fail(result.msg)
      return
    }

    Toast.success('找回成功')
    navigation.navigate('Login')
  }

  return (
    <Background
      containerStyle={{
        justifyContent: 'flex-start'
      }}
    >
      <AreaInput
        withShadow
        defaultCode="CN"
        onChangeText={text => setName({ value: text, error: '' })}
        onChangeCountry={country => {
          setArea({ value: '+' + country.callingCode })
        }}
        placeholder="请输入手机号码"
        layout="first"
      ></AreaInput>
      <SMSTextInput
        onChangeText={text => setCode(text.trim())}
        smsOpts={{
          to: name.value,
          area: area.value,
          type: 2
        }}
      />
      <PasswordInputText
        label="密码"
        onChangeText={text =>
          setPassword(prev => ({ ...prev, password: text }))
        }
        placeholder="请输入密码"
      />
      <PasswordInputText
        // dense
        label="确认密码"
        onChangeText={text =>
          setPassword(prev => ({ ...prev, confirmPassword: text }))
        }
        placeholder="请输入确认密码"
        errorText="密码长度8-32位，须包含数字、字母、符号至少2种以上元素"
      />
      <Button mode="contained" onPress={handleSmsCodeValid}>
        找回密码
      </Button>
    </Background>
  )
}

export default ForgotPassword
